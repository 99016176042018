import { css } from '@emotion/react';
import {
  ActionIcon,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Loader,
  Modal,
  Paper,
  Radio,
  Rating,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconExclamationCircle, IconPlus, IconSearch, IconX } from '@tabler/icons-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../services/master.service';
import { image_url, is_mobile_view } from '../config';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Carousel } from '@mantine/carousel';
import ProductCard from '../components/ProductCard';
import { showErrorToast, showSuccessToast } from '../utilities/Toast';
import CustomSlider from '../components/CustomSlider';
import { IconFilter } from '@tabler/icons';
import { CartContext } from '../App';

function CategoryPage() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const { cartItemCount, setCartItemCount } = useContext(CartContext);

  const [searchParams, setsSearchParams] = useSearchParams();
  const [subCategories, setSubCategories] = useState([]);
  const [title, setTitle] = useState();
  const navigate = useNavigate();

  const [searchString, setSearchString] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [displayProducts, setDisplayProducts] = useState([]);
  const [productBanners, setProductBanners] = useState([]);
  const [offerBanners, setOfferBanners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filter, setFilter] = useState(false);

  
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");//2
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );//2
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) and (max-width : 1200px)"
  );//3
  const isExtraLargeDevice = useMediaQuery(
    "only screen and (min-width : 1201px)"
  );//4
  const [productRequest, setProductRequest] = useState(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0);

  useEffect(()=>{
    setProductRequest(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0);
    
    if ((isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0) !==0) {
      api.get_products(1, (isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0), searchString, searchParams.get('id'), selectedSubCategory, null).then((res) => {
        setPage(1);
        console.log(res.data.data);
        setDisplayProducts(res.data.data);

        if ((res.data.success && res.data.data.length<(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0)) || res.data.data.length===0) {
          setHasMore(false);
        }
      });
    }
  },[isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice])
  
  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current.el.scrollTop=0;
  }

  const addToCart = async (id) => {
    await api
      .add_to_cart({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setCartItemCount(res.data.data?.cartitem.length);
          showSuccessToast({
            title: 'Success',
            message: 'Item successfully added to cart.',
          });
        } else {
          if (res.data.code === 401) {
            navigate('/login');
            showErrorToast({
              title: 'Warning',
              message: 'You have to login to add items to cart.',
            });
          } else {
            showErrorToast({
              title: 'Error',
              message: res.data.message,
            });
          }
        }
      });
  };

  useEffect(() => {
    api.get_categories().then((res) => {
      let categoryId = searchParams.get('id');
      let category = res.data.data.find((e) => e.id === +categoryId);
      setTitle(category.title);
      setSubCategories(category.sub_category);
      setCategories(res.data.data);
    });
    setSelectedSubCategory(parseInt(searchParams.get('subcategory')));
  }, [searchParams]);

  const fetchData = async (pg) => {
    setIsLoading(true);
    await api
      .get_products(
        pg,
        productRequest,
        searchString,
        searchParams.get('id'),
        selectedSubCategory,
        null,
      )
      .then((res) => {
        console.log(res.data.data);
        if (res.data.success) {
          let oldProducts = displayProducts;
          let newProducts = [...oldProducts, ...res.data.data];
          setDisplayProducts(newProducts);
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      });
    setIsLoading(false);
  };

  useEffect(() => {
    if (productRequest!==0) {
      api.get_products(1, productRequest, searchString, searchParams.get('id'), selectedSubCategory, null).then((res) => {
        setPage(1);
        console.log(res.data.data);
        setDisplayProducts(res.data.data);
        scrollToTop();

        if ((res.data.success && res.data.data.length<productRequest) || res.data.data.length===0) {
          setHasMore(false);
        }else{
          setHasMore(true);
        }
      });
    }
  }, [searchString, selectedSubCategory, searchParams]);

  useEffect(() => {
    api.get_banners().then((res) => {
      console.log(res.data.data);
      let product_banners = res.data.data.filter((e) => e.product_banner === true);
      setProductBanners(product_banners);
      let offer_banners = res.data.data.filter((e) => e.offer_banner === true);
      setOfferBanners(offer_banners);
    });
    //setSelectedSubCategory(parseInt(searchParams.get('subcategory')));
  }, []);

  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <Box mb={isMobileScreen ? 14 : 24} px={10}>
          <Breadcrumbs separator="/">
            <Link
              size={14}
              to={'/'}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
              }}
            >
              Home
            </Link>
            <Link
              size={14}
              to={'/categories'}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
              }}
            >
              Categories
            </Link>
            <Text size={14} color="dimmed">
              {title}
            </Text>
          </Breadcrumbs>
        </Box>

        <CustomSlider slidesToShow={isMobileScreen ? 1 : 3}>
          {offerBanners.map((e) => (
            <div>
              <Box
                p={2}
                onClick={() => {
                  if (categories.length > 0) {
                    if (e.product !== null) navigate(`/product?id=${e.product}`);
                  }
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = 'pointer';
                }}
              >
                <img
                  alt=""
                  src={image_url + e.image}
                  width={'100%'}
                  height={'100%'}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </div>
          ))}
        </CustomSlider>

        {/* Categories */}
        <Box p={10}>
          <Flex align={'center'} justify={'space-between'} mb={20}>
            <Text size={'xl'} fw={600} tt={'capitalize'}>
              {title}
            </Text>
            {isMobileScreen ? (
              <IconFilter
                onClick={() => {
                  setFilter(true);
                }}
                size={'26px'}
                color="green"
                style={{
                  border: '1px solid green',
                  borderRadius: '50%',
                  padding: '2px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <></>
            )}
          </Flex>

          <Grid>
            {isMobileScreen ? (
              <></>
            ) : (
              <Grid.Col span={12} md={4} lg={3}>
                <Paper p={18} radius={'sm'}>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text size={14} fw={600} mb={0}>
                      Categories
                    </Text>
                    <Button
                      size="xs"
                      variant="subtle"
                      color="gray"
                      leftIcon={<IconX size={11} />}
                      onClick={() => {
                        setSelectedSubCategory('');
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Flex>
                  <Radio.Group
                    mt={20}
                    name="subCategory"
                    value={selectedSubCategory}
                    onChange={(e) => {
                      setSelectedSubCategory(+e);
                    }}
                  >
                    {subCategories.map((e) => (
                      <Radio
                        mb={12}
                        size="sm"
                        value={e.id}
                        label={e.title}
                        fw={e.id === selectedSubCategory ? 900 : 500}
                        style={{
                          textTransform: 'capitalize',
                          textDecorationLine:
                            e.id === selectedSubCategory ? 'underline' : 'none',
                        }}
                      />
                    ))}
                  </Radio.Group>
                </Paper>

                <Grid w={'100%'} mt={20}>
                  {productBanners?.map((e, i) => (
                    <Grid.Col
                      span={6}
                      onClick={() => {
                        if (categories.length > 0) {
                          if (e.product !== null) navigate(`/product?id=${e.product}`);
                        }
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = 'pointer';
                      }}
                    >
                      <Flex align={'center'} justify={'center'} w={'100%'}>
                        <img
                          alt=""
                          src={image_url + e.image}
                          height={'210px'}
                          style={{
                            objectFit: 'contain',
                          }}
                        />
                      </Flex>
                    </Grid.Col>
                  ))}
                </Grid>
              </Grid.Col>
            )}
            <Grid.Col span={12} md={8} lg={9}>
              {/* <TextInput
                placeholder="Search Products.."
                mb={20}
                variant="default"
                icon={<IconSearch size={14} />}
                // w={'100%'}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
                w={'100%'}
              /> */}
              {!displayProducts.length && (
                <Box w={'100%'}>
                  <Alert
                    variant="light"
                    color="yellow"
                    styles={{
                      title: {
                        margin: 0,
                      },
                    }}
                    title={'No Products Found.'}
                    icon={<IconExclamationCircle />}
                  />
                </Box>
              )}
              {displayProducts && (
                <InfiniteScroll
                ref={scrollRef}
                height={1200}//!displayProducts.length ? 'auto' : isMobileScreen ? 768 : 1080}
                style={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
                dataLength={displayProducts.length} //This is important field to render the next data
                next={() => {
                  fetchData(page + 1);
                }}
                hasMore={hasMore}
                // scrollThreshold={'200px'}
                loader={<Flex align={"center"} justify={"center"}>
                  <Loader />
                </Flex>}
                  endMessage={
                    displayProducts.length ? (
                      <p style={{ textAlign: 'center' }}>
                        <b>You have seen all the products.</b>
                      </p>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Box>
                    <Grid>
                      {displayProducts &&
                        displayProducts.map((e) => (
                          <Grid.Col span={6} md={4} lg={3} key={e.id}>
                            <Box w={'100%'} mr={20} pb={20}>
                              <ProductCard object={e} addToCart={addToCart} />
                            </Box>
                          </Grid.Col>
                        ))}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              )}
            </Grid.Col>
          </Grid>
        </Box>
        <Modal
          opened={filter}
          onClose={() => {
            setFilter(false);
          }}
          title="Filter Products"
          centered
        >
          <Paper p={18} radius={'sm'}>
            <Flex justify={'space-between'} align={'center'}>
              <Text size={14} fw={600} mb={0}>
                Categories
              </Text>
              <Button
                size="xs"
                variant="subtle"
                color="gray"
                leftIcon={<IconX size={11} />}
                onClick={() => {
                  setSelectedSubCategory('');
                  setFilter(false);
                }}
              >
                Clear Filters
              </Button>
            </Flex>
            <Radio.Group
              mt={20}
              name="subCategory"
              value={selectedSubCategory}
              onChange={(e) => {
                setSelectedSubCategory(+e);
              }}
            >
              {subCategories.map((e) => (
                <Radio
                  mb={12}
                  size="sm"
                  value={e.id}
                  label={e.title}
                  fw={e.id === selectedSubCategory ? 900 : 500}
                  onClick={() => {
                    setFilter(false);
                  }}
                  style={{
                    textTransform: 'capitalize',
                    textDecorationLine:
                      e.id === selectedSubCategory ? 'underline' : 'none',
                  }}
                />
              ))}
            </Radio.Group>
          </Paper>

          <Grid w={'100%'} mt={20}>
            {productBanners?.map((e, i) => (
              <Grid.Col
                span={6}
                onClick={() => {
                  if (categories.length > 0) {
                    if (e.product !== null) navigate(`/product?id=${e.product}`);
                  }
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = 'pointer';
                }}
              >
                <Flex align={'center'} justify={'center'} w={'100%'}>
                  <img
                    alt=""
                    src={image_url + e.image}
                    height={'210px'}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </Flex>
              </Grid.Col>
            ))}
          </Grid>
        </Modal>
      </Container>
    </div>
  );
}

export default CategoryPage;
