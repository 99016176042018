import { css } from '@emotion/react';
import {
  ActionIcon,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CloseButton,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Input,
  Paper,
  Radio,
  Rating,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconArrowRight,
  IconExclamationCircle,
  IconMinus,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { api } from '../services/master.service';
import { carts } from '../services/website.service';
import { Link, useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../utilities/Toast';
import { Player } from '@lottiefiles/react-lottie-player';
import { createAlert } from '../components/Notification';
import { CartContext } from '../App';
import { is_mobile_view } from '../config';

function CartPage() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const [cart, setCart] = useState({});
  const [coupon, setCoupon] = useState();
  const { cartItemCount, setCartItemCount } = useContext(CartContext);

  const [cartDetails, setCartDetails] = useState();
  const [cartItems, setCartItems] = useState();

  const [productWithoutCod, setProductWithoutCod] = useState();
  const [discount, setDiscount] = useState(0.0);

  const navigate = useNavigate('');

  useEffect(() => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
    });
  }, []);

  const getData = () => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
    });
  };

  const updateQty = (id, qty) => {
    if (qty < 1) {
      removeItem(id);
    } else {
      api
        .update_cart({
          item_id: id,
          quantity: qty,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            showSuccessToast({
              title: 'Success',
              message: 'Cart Updated.',
            });
            getData();
          } else {
            showErrorToast({
              title: 'Error',
              message: 'Error updating cart.',
            });
          }
        });
    }
  };

  const removeItem = (id) => {
    api
      .remove_cart_item({
        item_id: id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setCartItemCount(res.data.data?.cartitem.length);
          showSuccessToast({
            title: 'Success',
            message: 'Cart Updated.',
          });
          getData();
        } else {
          showErrorToast({
            title: 'Error',
            message: 'Error updating cart.',
          });
        }
      });
  };

  const applyCoupon = () => {
    carts
      .checkCoupon({
        code: coupon,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          createAlert('success', 'Coupon Applied Successfully.');
          setCart(res.data);
        } else {
          createAlert('error', res.message);
        }
      });
  };

  const getCart = () => {
    var saved = 0.0;
    cart?.cartitem?.map((e, i) => {
      saved += e.mrp * e.quantity - e.total + parseFloat(cart.discount_total);
      console.log(e.mrp, e.total, e.quantity, parseFloat(cart.discount_total));
    });
    return saved !== 0 ? `You have saved ₹${saved.toFixed(2)} in this order` : '';
  };

  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <Box mb={isMobileScreen ? 14 : 24} px={10}>
          <Breadcrumbs separator="/">
            <Text size={14} color="dimmed">
              Home
            </Text>
            <Text size={14}>Cart</Text>
          </Breadcrumbs>
        </Box>

        {/* Categories */}
        <Box p={10}>
          <Flex align={'center'} justify={'space-between'}>
            <Text size={'xl'} mb={20} fw={600}>
              Cart
            </Text>

            <Button
              variant="outline"
              onClick={() => {
                navigate('/products');
              }}
            >
              Browse Products
            </Button>
          </Flex>

          {/* <Text size={14} fw={600}>
            Your Cart Items
          </Text> */}

          {cart?.cartitem?.length === 0 && (
            <Box>
              <Player
                style={{ height: '325px', width: '325px' }}
                autoplay
                loop={true}
                speed="1"
                src="https://assets4.lottiefiles.com/packages/lf20_qh5z2fdq.json"
              />
              <Flex direction={'column'} align={'center'} justify={'center'} gap={20}>
                <Text fw={900} fz={'lg'}>
                  Oops, It's empty!
                </Text>
                <Button
                  variant="outline"
                  onClick={() => {
                    navigate('/products');
                  }}
                >
                  Browse Products
                </Button>
              </Flex>
            </Box>
          )}

          {cart?.cartitem?.length >= 1 && (
            <Grid>
              <Grid.Col span={12} md={9} lg={9}>
                {isMobileScreen ? (
                  <Paper mt={10} p={18} w={'100%'} radius={'sm'}>
                    <table width={'100%'} align="center">
                      <thead>
                        <tr>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                            align="left"
                          >
                            <Text size={14}>Product</Text>
                          </th>
                          {/* <th
                          style={{
                            paddingBottom: 20,
                            borderBottom: '1px solid #eee',
                          }}
                        >
                          <Text size={14}>Price</Text>
                        </th> */}
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Quantity</Text>
                          </th>
                          {/* <th
                          style={{
                            paddingBottom: 20,
                            borderBottom: '1px solid #eee',
                          }}
                        >
                          <Text size={14}>Tax Total</Text>
                        </th> */}
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Total</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart?.cartitem?.map((e) => (
                          <tr key={e.id}>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="left"
                              width={350}
                            >
                              <Text size={14}>{e.product}</Text>
                              <Flex gap={5}>
                                <Text td="line-through" color="grey" size={14}>
                                  {' '}
                                  ₹ {e.mrp}
                                </Text>
                                <Text size={14}>₹ {e.selling_price}</Text>
                              </Flex>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Flex w={'100%'} align={'center'} justify={'center'}>
                                <Flex align={'center'}>
                                  <ActionIcon
                                    style={{
                                      border: '1px solid #eee',
                                    }}
                                    onClick={() => {
                                      updateQty(e.id, e.quantity - 1);
                                    }}
                                  >
                                    <IconMinus size={12} />
                                  </ActionIcon>
                                  <Text size={14} mx={14}>
                                    {e.quantity}
                                  </Text>
                                  <ActionIcon
                                    style={{
                                      border: '1px solid #eee',
                                    }}
                                    onClick={() => {
                                      updateQty(e.id, e.quantity + 1);
                                    }}
                                  >
                                    <IconPlus size={12} />
                                  </ActionIcon>
                                </Flex>
                              </Flex>
                            </td>
                            {/* <td
                            style={{
                              paddingTop: 20,
                            }}
                            align="center"
                          >
                            <Text size={14}> ₹ {+e.tax_total.toFixed(2)} </Text>
                          </td> */}
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Text size={14}> {e.total} </Text>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                            >
                              <Flex justify={'center'} align={'flex-end'}>
                                <ActionIcon
                                  color="red"
                                  style={{
                                    border: '1px solid #eee',
                                  }}
                                  onClick={() => {
                                    removeItem(e.id);
                                  }}
                                >
                                  <IconTrash size={14} />
                                </ActionIcon>
                              </Flex>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Paper>
                ) : (
                  <Paper mt={10} p={18} w={'100%'} radius={'sm'}>
                    <table width={'100%'} align="center">
                      <thead>
                        <tr>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                            align="left"
                          >
                            <Text size={14}>Product</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Price</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Quantity</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Tax Total</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          >
                            <Text size={14}>Sub Total</Text>
                          </th>
                          <th
                            style={{
                              paddingBottom: 20,
                              borderBottom: '1px solid #eee',
                            }}
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart?.cartitem?.map((e) => (
                          <tr key={e.id}>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="left"
                              width={350}
                            >
                              <Text size={14}>
                                {/* <Link
                              to={`/product?id=${e.id}`}
                              style={{
                                color: 'inherit',
                                textDecoration: 'none',
                              }}
                            > */}
                                {e.product}
                                {/* </Link> */}
                              </Text>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Flex gap={5}>
                                <Text td="line-through" color="grey" size={14}>
                                  {' '}
                                  ₹ {e.mrp}
                                </Text>
                                <Text size={14}>₹ {e.selling_price}</Text>
                              </Flex>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Flex w={'100%'} align={'center'} justify={'center'}>
                                <Flex align={'center'}>
                                  <ActionIcon
                                    style={{
                                      border: '1px solid #eee',
                                    }}
                                    onClick={() => {
                                      updateQty(e.id, e.quantity - 1);
                                    }}
                                  >
                                    <IconMinus size={12} />
                                  </ActionIcon>
                                  <Text size={14} mx={14}>
                                    {e.quantity}
                                  </Text>
                                  <ActionIcon
                                    style={{
                                      border: '1px solid #eee',
                                    }}
                                    onClick={() => {
                                      updateQty(e.id, e.quantity + 1);
                                    }}
                                  >
                                    <IconPlus size={12} />
                                  </ActionIcon>
                                </Flex>
                              </Flex>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Text size={14}> ₹ {+e.tax_total.toFixed(2)} </Text>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                              align="center"
                            >
                              <Text size={14}> ₹ {e.total} </Text>
                            </td>
                            <td
                              style={{
                                paddingTop: 20,
                              }}
                            >
                              <Flex justify={'center'} align={'flex-end'}>
                                <ActionIcon
                                  color="red"
                                  style={{
                                    border: '1px solid #eee',
                                  }}
                                  onClick={() => {
                                    removeItem(e.id);
                                  }}
                                >
                                  <IconTrash size={14} />
                                </ActionIcon>
                              </Flex>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Paper>
                )}
                <Box p={10}>
                  <Flex>
                    <Text size={'lg'} fw={700} color="orange">
                      {getCart()}
                    </Text>
                  </Flex>
                </Box>
              </Grid.Col>
              <Grid.Col span={12} md={3} lg={3}>
                <Paper mt={10} p={18} w={'100%'} radius={'sm'}>
                  <Text
                    size={16}
                    fw={600}
                    style={{
                      paddingBottom: 10,
                    }}
                  >
                    Apply Coupon
                  </Text>
                  <Box mt={20}>
                    <Flex justify={'space-between'} mb={20} gap={10}>
                      <Input
                        placeholder="Coupon Code"
                        value={coupon}
                        onChange={(event) => setCoupon(event.currentTarget.value)}
                        rightSectionPointerEvents="all"
                        rightSection={
                          <CloseButton
                            //aria-label="Clear input"
                            onClick={() => setCoupon('')}
                            style={{ display: coupon ? undefined : 'none' }}
                          />
                        }
                      />
                      <Button onClick={applyCoupon}>Apply</Button>
                    </Flex>
                  </Box>
                </Paper>
                <Paper mt={10} p={18} w={'100%'} radius={'sm'}>
                  <Text
                    size={16}
                    fw={600}
                    style={{
                      paddingBottom: 10,
                    }}
                  >
                    Cart Summary
                  </Text>
                  <Box mt={20}>
                    <Flex
                      justify={'space-between'}
                      mb={20}
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 15,
                      }}
                    >
                      <Text align="left" size={14}>
                        Subtotal
                      </Text>
                      <Text align="right" size={14}>
                        ₹ {cart?.sub_total}
                      </Text>
                    </Flex>
                    <Flex
                      justify={'space-between'}
                      mb={20}
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 15,
                      }}
                    >
                      <Text align="left" size={14}>
                        Tax Total ( Inclusive )
                      </Text>
                      <Text align="right" size={14}>
                        ₹ {cart?.tax_total}
                      </Text>
                    </Flex>
                    <Flex
                      justify={'space-between'}
                      mb={20}
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 15,
                      }}
                    >
                      <Text align="left" size={14}>
                        Shipping
                      </Text>
                      <Text align="right" size={14}>
                        ₹ {cart?.delivery}
                      </Text>
                    </Flex>
                    <Flex
                      justify={'space-between'}
                      mb={20}
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 15,
                      }}
                    >
                      <Text align="left" size={14}>
                        Packaging
                      </Text>
                      <Text align="right" size={14}>
                        ₹ {cart?.packaging}
                      </Text>
                    </Flex>
                    <Flex
                      justify={'space-between'}
                      mb={20}
                      style={{
                        borderBottom: '1px solid #eee',
                        paddingBottom: 15,
                      }}
                    >
                      <Text align="left" size={14}>
                        Total Discount
                      </Text>
                      <Text align="right" size={14}>
                        ₹ {cart?.discount_total}
                      </Text>
                    </Flex>
                    <Flex
                      direction={'column'}
                      mb={10}
                      style={{
                        paddingBottom: 15,
                      }}
                    >
                      <Flex
                        justify={'space-between'}
                        style={{
                          paddingBottom: 5,
                        }}
                      >
                        <Text align="left" size={16} fw={600}>
                          Total
                        </Text>
                        <Text align="right" size={16} fw={600}>
                          ₹ {cart?.total}
                        </Text>
                      </Flex>
                      <Flex justify={'right'}>
                        <Text size={12} fw={700} color="orange">
                          {cart?.discount_total !== '0.00'
                            ? `You Save ₹ ${cart?.discount_total}`
                            : ''}
                        </Text>
                      </Flex>
                    </Flex>
                  </Box>
                  <Button
                    w={'100%'}
                    rightIcon={<IconArrowRight size={17} />}
                    onClick={() => {
                      navigate('/checkout');
                    }}
                  >
                    Proceed To Checkout
                  </Button>
                </Paper>
              </Grid.Col>
            </Grid>
          )}
        </Box>
      </Container>
    </div>
  );
}

export default CartPage;
