import { css } from '@emotion/react';
import {
  ActionIcon,
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Loader,
  Menu,
  Modal,
  Paper,
  Radio,
  Rating,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconChevronDown,
  IconClearAll,
  IconPlus,
  IconSearch,
  IconX,
} from '@tabler/icons-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { api } from '../services/master.service';
import { image_url, is_mobile_view } from '../config';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconExclamationCircle, IconFilter } from '@tabler/icons';
import { useNavigate } from 'react-router';
import { showErrorToast, showSuccessToast } from '../utilities/Toast';
import { Link, useSearchParams } from 'react-router-dom';
import ProductCard from '../components/ProductCard';
import { CartContext } from '../App';

function AllProducts() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  const product_view = is_mobile_view && !isMobileScreen;
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { cartItemCount, setCartItemCount, search, setSearch } = useContext(CartContext);

  const [displayProducts, setDisplayProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [filter, setFilter] = useState(false);

  //
  //const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState('');
  const [valueSubCategory, setValueSubCategory] = useState('');
  const [vendorBanners, setVendorBanners] = useState([]);

  
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");//2
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );//2
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) and (max-width : 1200px)"
  );//3
  const isExtraLargeDevice = useMediaQuery(
    "only screen and (min-width : 1201px)"
  );//4
  const [productRequest, setProductRequest] = useState(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0);

  useEffect(()=>{
    setProductRequest(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0);
    
    if ((isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0) !==0) {
      api.get_products(1, (isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0), search, selectedCategory, value, sort).then((res) => {
        setPage(1);
        // console.log(res.data.data);
        setDisplayProducts(res.data.data);

        if ((res.data.success && res.data.data.length<(isSmallDevice||isMediumDevice?10:isLargeDevice?12:isExtraLargeDevice?16:0)) || res.data.data.length===0) {
          setHasMore(false);
        }
      });
    }
  },[isSmallDevice, isMediumDevice, isLargeDevice, isExtraLargeDevice])

  const scrollRef = useRef();
  const scrollToTop = () => {
    scrollRef.current.el.scrollTop=0;
  }

  const clearFilters = () => {
    setPage(1);
    setSearch('');
  };

  useEffect(() => {
    if (searchParams.get('search') === '') {
      clearFilters();
    }
  }, [searchParams]);

  useEffect(() => {
    if (productRequest!==0) {
      api.get_products(1, productRequest, search, selectedCategory, value, sort).then((res) => {
        setPage(1);
        // console.log(res.data.data);
        setDisplayProducts(res.data.data);
        scrollToTop();

        if ((res.data.success && res.data.data.length<productRequest) || res.data.data.length===0) {
          setHasMore(false);
        }else{
          setHasMore(true);
        }
      });
    }
  }, [selectedCategory, sort, searchParams, value]);

  useEffect(() => {
    api.get_categories().then((res) => {
      // console.log(res.data.data);
      setCategories(res.data.data);
    });
  }, []);

  const fetchData = async (pg) => {
    setIsLoading(true);
    await api.get_products(pg, productRequest, search, selectedCategory, value, sort).then((res) => {
      // console.log(res.data.data);
      if (res.data.success) {
        let oldProducts = displayProducts;
        let newProducts = [...oldProducts, ...res.data.data];
        setDisplayProducts(newProducts);
        setPage(page + 1);
      } else {
        setHasMore(false);
      }
    });
    setIsLoading(false);
  };

  const addToCart = async (id) => {
    await api
      .add_to_cart({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setCartItemCount(res.data.data?.cartitem.length);
          showSuccessToast({
            title: 'Success',
            message: 'Item successfully added to cart.',
          });
        } else {
          if (res.data.code === 401) {
            navigate('/login');
            showErrorToast({
              title: 'Warning',
              message: 'You have to login to add items to cart.',
            });
          } else {
            showErrorToast({
              title: 'Error',
              message: res.data.message,
            });
          }
        }
      });
  };

  useEffect(() => {
    api.get_banners().then((res) => {
      // console.log(res.data.data);
      let vendor_banners = res.data.data.filter((e) => e.vendor_banner === true);
      setVendorBanners(vendor_banners);
    });
  }, []);

  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <Box mb={isMobileScreen ? 0 : 20} px={10}>
          <Flex align={'center'} justify={'space-between'}>
            <Breadcrumbs separator="/">
              <Link
                size={14}
                to={'/'}
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                  fontSize: 14,
                }}
              >
                Home
              </Link>
              <Text size={14} color="dimmed">
                All Products
              </Text>
            </Breadcrumbs>
            {isMobileScreen ? (
              <IconFilter
                onClick={() => {
                  setFilter(true);
                }}
                size={'26px'}
                color="green"
                style={{
                  border: '1px solid green',
                  borderRadius: '50%',
                  padding: '2px',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <></>
            )}
          </Flex>
        </Box>

        {/* Categories */}
        <Box p={10}>
          {isMobileScreen ? (
            <></>
          ) : (
            <Flex align={'center'} justify={'space-between'} mb={isMobileScreen ? 5 : 18}>
              <Text size={'xl'} fw={600}>
                All Products
              </Text>
            </Flex>
          )}
          <Grid>
            {isMobileScreen ? (
              <></>
            ) : (
              <Grid.Col span={12} md={4} lg={3}>
                <Paper p={18} radius={'sm'}>
                  <Flex justify={'space-between'} align={'center'}>
                    <Text size={14} fw={600} mb={0}>
                      Categories
                    </Text>
                    <Button
                      size="xs"
                      variant="subtle"
                      color="gray"
                      leftIcon={<IconX size={11} />}
                      onClick={() => {
                        setValue('');
                        setValueSubCategory('');
                        setSelectedCategory('');
                        setSort('');
                        setSearch('');
                        searchParams.set('search', '');
                      }}
                    >
                      Clear Filters
                    </Button>
                  </Flex>
                  <Radio.Group
                    mt={20}
                    name="category"
                    value={selectedCategory}
                    onChange={(e) => {
                      setSelectedCategory(+e);
                      setSearch('');
                    }}
                  >
                    {categories.map((e) => (
                      <Radio
                        key={e.id}
                        mb={12}
                        size="sm"
                        fw={e.id === selectedCategory ? 900 : 500}
                        value={+e.id}
                        style={{
                          textTransform: 'capitalize',
                          textDecorationLine:
                            e.id === selectedCategory ? 'underline' : 'none',
                        }}
                        label={e.title}
                        onClick={() => {
                          setValue('');
                        }}
                      />
                    ))}
                  </Radio.Group>
                </Paper>
                <Grid w={'100%'} mt={20}>
                  {vendorBanners?.map((e, i) => (
                    <Grid.Col
                      span={6}
                      onClick={() => {
                        if (categories.length > 0) {
                          if (e.product !== null) navigate(`/product?id=${e.product}`);
                        }
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = 'pointer';
                      }}
                    >
                      <Flex align={'center'} justify={'center'} w={'100%'}>
                        <img
                          alt=""
                          src={image_url + e.image}
                          height={'210px'}
                          style={{
                            objectFit: 'contain',
                          }}
                        />
                      </Flex>
                    </Grid.Col>
                  ))}
                </Grid>
              </Grid.Col>
            )}
            <Grid.Col span={12} md={product_view ? 12 : 8} lg={product_view ? 12 : 9}>
              {searchParams.get('search') && (
                <Text mb={20}> Showing results for '{search}' </Text>
              )}
              <Flex mb={20} w={'100%'}>
                <Chip.Group multiple={false}>
                  {categories.map((e) =>
                    e.id === selectedCategory ? (
                      <Grid
                        w={'100%'}
                        h={isMobileScreen ? 85 : 85}
                        // direction={'column'}
                        // gap={'sm'}
                        style={{ overflowY: 'auto' }}
                        grow
                      >
                        {e.sub_category.map((val, i) => (
                          <Grid.Col span={'auto'}>
                            <Chip
                              color={theme.colors.brand[8]}
                              variant="light"
                              checked={(valueSubCategory===val.title)}
                              onClick={() => {
                                setValueSubCategory(val.title);
                                setValue(val.id);
                              }}
                            >
                              {val.title}
                            </Chip>
                          </Grid.Col>
                        ))}
                      </Grid>
                    ) : (
                      <></>
                    ),
                  )}
                </Chip.Group>
              </Flex>
              {isMobileScreen ? (
                <></>
              ) : (
                <Flex>
                  {/* <TextInput
                  placeholder="Search Products.."
                  mb={20}
                  variant="default"
                  icon={<IconSearch size={14} />}
                  // w={'100%'}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                  w={'100%'}
                /> */}
                  <Flex
                    width={'100%'}
                    justify={'right'}
                    ml={10}
                    pt={5}
                    style={{
                      flex: '1',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <Text size={'12px'} mr={10}>
                      Sort By Price :
                    </Text>
                    <Flex>
                      <Radio
                        name="pricesort"
                        mb={12}
                        size="xs"
                        onChange={(e) => {
                          setSort(e.target.value);
                        }}
                        value={'-selling_price'}
                        label={'High to low'}
                      />
                      <Radio
                        name="pricesort"
                        ml={10}
                        mb={12}
                        size="xs"
                        onChange={(e) => {
                          setSort(e.target.value);
                        }}
                        value={'selling_price'}
                        label={'Low to high'}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
              {!displayProducts.length && (
                <Box w={'100%'}>
                  <Alert
                    variant="light"
                    color="yellow"
                    styles={{
                      title: {
                        margin: 0,
                      },
                    }}
                    title={'No Products Found.'}
                    icon={<IconExclamationCircle />}
                  />
                </Box>
              )}

              {displayProducts && (
                <InfiniteScroll
                  ref={scrollRef}
                  height={1200}//!displayProducts.length ? 'auto' : isMobileScreen ? 768 : 1080}
                  style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                  dataLength={displayProducts.length} //This is important field to render the next data
                  next={() => {
                    fetchData(page + 1);
                  }}
                  hasMore={hasMore}
                  // scrollThreshold={'200px'}
                  loader={<Flex align={"center"} justify={"center"}>
                    <Loader />
                  </Flex>}
                  endMessage={
                    displayProducts.length ? (
                      <p style={{ textAlign: 'center' }}>
                        <b>You have seen all the products.</b>
                      </p>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Box>
                    <Grid>
                      {displayProducts &&
                        displayProducts.map((e) => (
                          <Grid.Col span={6} md={4} lg={3} key={e.id}>
                            <Box w={'100%'} mr={20} pb={20}>
                              <ProductCard object={e} addToCart={addToCart} />
                            </Box>
                          </Grid.Col>
                        ))}
                    </Grid>
                  </Box>
                </InfiniteScroll>
              )}
            </Grid.Col>
          </Grid>
        </Box>

        <Modal
          opened={filter}
          onClose={() => {
            setFilter(false);
          }}
          title="Filter Products"
          centered
        >
          <Paper p={8} radius={'sm'}>
            <Flex>
              {/* <TextInput
                  placeholder="Search Products.."
                  mb={20}
                  variant="default"
                  icon={<IconSearch size={14} />}
                  // w={'100%'}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                  }}
                  w={'100%'}
                /> */}
              <Flex
                width={'100%'}
                mb={5}
                style={{
                  flex: '1',
                  whiteSpace: 'nowrap',
                }}
              >
                <Text size={'12px'} mr={10}>
                  Sort By Price :
                </Text>
                <Flex>
                  <Radio
                    name="pricesort"
                    mb={12}
                    size="xs"
                    onChange={(e) => {
                      setSort(e.target.value);
                      setFilter(false);
                    }}
                    value={'-selling_price'}
                    label={'High to low'}
                  />
                  <Radio
                    name="pricesort"
                    ml={10}
                    mb={12}
                    size="xs"
                    onChange={(e) => {
                      setSort(e.target.value);
                      setFilter(false);
                    }}
                    value={'selling_price'}
                    label={'Low to high'}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Divider />
            <Flex justify={'space-between'} align={'center'} mt={5}>
              <Text size={14} fw={600} mb={0}>
                Categories
              </Text>
              <Button
                size="xs"
                variant="subtle"
                color="gray"
                leftIcon={<IconX size={11} />}
                onClick={() => {
                  setValue('');
                  setValueSubCategory('');
                  setSelectedCategory('');
                  setSort('');
                  setSearch('');
                  searchParams.set('search', '');
                  setFilter(false);
                }}
              >
                Clear Filters
              </Button>
            </Flex>
            <Radio.Group
              mt={20}
              name="category"
              value={selectedCategory}
              onChange={(e) => {
                setSelectedCategory(+e);
              }}
            >
              {categories.map((e) => (
                <Radio
                  key={e.id}
                  mb={12}
                  size="sm"
                  fw={e.id === selectedCategory ? 900 : 500}
                  value={+e.id}
                  style={{
                    textTransform: 'capitalize',
                    textDecorationLine: e.id === selectedCategory ? 'underline' : 'none',
                  }}
                  label={e.title}
                  onClick={() => {
                    setValue('');
                    setFilter(false);
                  }}
                />
              ))}
            </Radio.Group>
          </Paper>
          <Grid w={'100%'} mt={20}>
            {vendorBanners?.map((e, i) => (
              <Grid.Col
                span={6}
                onClick={() => {
                  if (categories.length > 0) {
                    if (e.product !== null) navigate(`/product?id=${e.product}`);
                  }
                }}
                onMouseOver={(e) => {
                  e.target.style.cursor = 'pointer';
                }}
              >
                <Flex align={'center'} justify={'center'} w={'100%'}>
                  <img
                    alt=""
                    src={image_url + e.image}
                    height={'210px'}
                    style={{
                      objectFit: 'contain',
                    }}
                  />
                </Flex>
              </Grid.Col>
            ))}
          </Grid>
        </Modal>
      </Container>
    </div>
  );
}

export default AllProducts;
