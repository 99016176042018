import { Carousel } from '@mantine/carousel';
import {
  ActionIcon,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  HoverCard,
  Image,
  Menu,
  Modal,
  Paper,
  Rating,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowsMaximize,
  IconHeart,
  IconMoneybag,
  IconPercentage,
  IconPlus,
  IconStar,
  IconStars,
  IconTrendingUp,
} from '@tabler/icons-react';
import { useContext, useEffect, useRef, useState } from 'react';
import data from './DummyData.json';
import { api } from '../../services/master.service';
import { image_url, is_mobile_view } from '../../config';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import { useNavigate } from 'react-router';
import { IconChevronRight } from '@tabler/icons';
import ProductCard from '../../components/ProductCard';
import CustomSlider from '../../components/CustomSlider';
import { useMediaQuery } from '@mantine/hooks';
import { CartContext } from '../../App';

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${1} solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

export default function Home() {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const { classes } = useStyles();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  const banner_view = is_mobile_view && !isMobileScreen;
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const { cartItemCount, setCartItemCount } = useContext(CartContext);

  const [trendingProducts, setTrendingProducts] = useState([]);
  const [quickViewModalVisible, setQuickViewModalVisible] = useState(false);
  const [heroBanners, setHeroBanners] = useState([]);
  const [bottomBanners, setBottomBanners] = useState([]);
  const [midBanners, setMidBanners] = useState([]);
  const [affliatorBanners, setAffliatorBanners] = useState([]);

  const [latestProducts, setLatestProducts] = useState([]);
  const [dealProducts, setDealProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const addToCart = async (id) => {
    await api
      .add_to_cart({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setCartItemCount(res.data.data?.cartitem.length);
          showSuccessToast({
            title: 'Success',
            message: 'Item successfully added to cart.',
          });
        } else {
          if (res.data.code === 401) {
            navigate('/login');
            showErrorToast({
              title: 'Warning',
              message: 'You have to login to add items to cart.',
            });
          } else {
            showErrorToast({
              title: 'Error',
              message: res.data.message,
            });
          }
        }
      })
      .catch((err) => {
        showErrorToast({
          title: 'Error',
          message: err.message,
        });
      });
  };

  useEffect(() => {
    setTrendingProducts(data.data);

    api.get_home_products({ filter: 'latest' }).then((res) => {
      console.log(res.data.data);
      setLatestProducts(res.data.data);
    });

    api.get_home_products({ filter: 'deals' }).then((res) => {
      console.log(res.data.data);
      setDealProducts(res.data.data);
    });

    api.get_home_products({ filter: 'featured' }).then((res) => {
      console.log(res.data.data);
      setFeaturedProducts(res.data.data);
    });

    api.get_banners().then((res) => {
      console.log(res.data.data);
      let hero_banners = res.data.data.filter((e) => e.home_banner === true);
      setHeroBanners(hero_banners);
      let bottom_banners = res.data.data.filter((e) => e.bottom_banner === true);
      setBottomBanners(bottom_banners);
      let mid_banners = res.data.data.filter((e) => e.mid_banner === true);
      setMidBanners(mid_banners);
      let affliator_banners = res.data.data.filter((e) => e.affliator_banner === true);
      setAffliatorBanners(affliator_banners);
    });
  }, []);

  useEffect(() => {
    api.get_categories().then((res) => {
      console.log(res.data.data);
      setCategories(res.data.data);
    });
  }, []);

  return (
    <div style={{ overflow: 'hidden', marginTop: isMobileScreen ? '135px' : '170px' }}>
      <Container size={'xl'}>
        {/* Top Slider */}
        <Box my={5}>
          <Grid>
            <Grid.Col span={12} md={2} lg={2}>
              {/* Categories display */}
              <Grid>
                {affliatorBanners?.map((e, i) => (
                  <Grid.Col
                    span={6}
                    md={12}
                    lg={12}
                    onClick={() => {
                      if (categories.length > 0) {
                        if (e.product !== null) navigate(`/product?id=${e.product}`);
                      }
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = 'pointer';
                    }}
                  >
                    <img
                      alt=""
                      src={image_url + e.image}
                      width={'100%'}
                      height={'200px'}
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
            <Grid.Col span={12} md={10} lg={10}>
              <CustomSlider slidesToShow={1}>
                {heroBanners.map((e) => (
                  <div
                    h={400}
                    onClick={() => {
                      if (categories.length > 0) {
                        if (e.product !== null) navigate(`/product?id=${e.product}`);
                      }
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = 'pointer';
                    }}
                  >
                    <img
                      alt=""
                      src={image_url + e.image}
                      width={'100%'}
                      height={isMobileScreen ? (banner_view ? 400 : 'auto') : 400}
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ))}
              </CustomSlider>
            </Grid.Col>
          </Grid>
        </Box>

        {/* Best Seller Products */}
        <Box my={5}>
          <Flex align="center" my={10}>
            <IconStar color="red" size={'30px'} style={{ opacity: 0.5 }} />
            <Text fw={'700'} size="xl" ml={10} mb={0}>
              Latest Products
            </Text>
          </Flex>
          <Box mb={15}>
            <Carousel
              slideSize={isMobileScreen ? '50%' : '25%'}
              loop={true}
              align="start"
              slidesToScroll={1}
              slideGap={'sm'}
              withControls={isMobileScreen ? false : true}
              dragFree
            >
              {latestProducts.map((e) => {
                return (
                  <Carousel.Slide>
                    <Box>
                      <ProductCard object={e} addToCart={addToCart} />
                    </Box>
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          </Box>
        </Box>

        {/* Mid banner  - 1 */}
        <Box my={10}>
          <Text fw={700} my={10}>
            Deals you don't want to miss!
          </Text>
          <Grid>
            <Grid.Col span={12}>
              <CustomSlider slidesToShow={isMobileScreen ? 1 : 2}>
                {midBanners.map((e) => (
                  <div>
                    <Box
                      mr={5}
                      ml={5}
                      p={2}
                      style={{
                        border: `2px solid ${theme.colors.brand[2]}`,
                        borderRadius: '6px',
                      }}
                      onClick={() => {
                        if (categories.length > 0) {
                          if (e.product !== null) navigate(`/product?id=${e.product}`);
                        }
                      }}
                      onMouseOver={(e) => {
                        e.target.style.cursor = 'pointer';
                      }}
                    >
                      <img
                        alt=""
                        src={image_url + e.image}
                        width={'100%'}
                        height={'100%'}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </Box>
                  </div>
                ))}
              </CustomSlider>
            </Grid.Col>
          </Grid>
        </Box>

        {/* Deal Products */}
        <Box my={5}>
          <Flex align="center" my={10}>
            <IconPercentage color="red" size={'30px'} style={{ opacity: 0.5 }} />
            <Text fw={'700'} size="xl" ml={10} mb={0}>
              Deal Products
            </Text>
          </Flex>
          <Box>
            <Carousel
              slideSize={isMobileScreen ? '50%' : '25%'}
              loop={true}
              align="start"
              slidesToScroll={1}
              slideGap={'sm'}
              withControls={isMobileScreen ? false : true}
              dragFree
            >
              {dealProducts.map((e) => {
                return (
                  <Carousel.Slide>
                    <Box w={'100%'} mr={20} pb={20}>
                      <ProductCard object={e} addToCart={addToCart} />
                    </Box>
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          </Box>
        </Box>

        {/* Categories */}
        <Box>
          <Text fw={700}>Explore our Trending Categories</Text>
          <Grid
            my={5}
            h={isMobileScreen ? 165 * 3 : 165}
            // justify={'space-between'}
            // wrap={'wrap'}
            // gap={isMobileScreen ? 8 : 'lg'}
            sx={{ overflow: 'hidden' }}
          >
            {categories?.map((e, i) => (
              <Grid.Col span={'auto'}>
                {
                  <Box
                    bg={'transparent'}
                    w={'100%'}
                    miw={160}
                    h={160}
                    sx={{
                      '&:hover': {
                        border: `2px solid ${theme.colors.brand[5]}`,
                      },
                      cursor: 'pointer',
                    }}
                    style={{
                      borderRadius: '6px',
                      backgroundImage: `url(${
                        categories.length > 0 ? image_url + e.image : ''
                      })`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                    onClick={() => {
                      if (categories.length > 0) {
                        navigate(`/category?id=${e.id}`);
                      }
                    }}
                  >
                    <Flex
                      h={'100%'}
                      direction={'column'}
                      align={'center'}
                      justify={'center'}
                    >
                      <Text
                        ta={'center'}
                        fz={16}
                        fw={700}
                        color={'black'}
                        p={6}
                        style={{ borderRadius: '30px' }}
                        bg={'rgba(255, 255, 255,0.6)'}
                      >
                        {categories.length > 0 ? e.title : ''}
                        <img
                          src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                          width={30}
                        />
                      </Text>
                    </Flex>
                  </Box>
                }
              </Grid.Col>
            ))}

            {/* <Box
              bg={'transparent'}
              w={160}
              h={160}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 0 ? image_url + categories[0].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 0) {
                  navigate(`/category?id=${categories[0].id}`);
                }
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 0 ? categories[0].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box>

            <Box
              bg={'transparent'}
              w={160}
              h={160}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 1 ? image_url + categories[1].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 1) {
                  navigate(`/category?id=${categories[1].id}`);
                }
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 1 ? categories[1].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box>

            <Box
              bg={'transparent'}
              w={160}
              h={160}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 2 ? image_url + categories[2].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 2) {
                  navigate(`/category?id=${categories[2].id}`);
                }
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 2 ? categories[2].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box>

            <Box
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
              bg={'transparent'}
              w={160}
              h={160}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 3 ? image_url + categories[3].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 3) {
                  navigate(`/category?id=${categories[3].id}`);
                }
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 3 ? categories[3].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box>

            <Box
              bg={'transparent'}
              w={160}
              h={160}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 4 ? image_url + categories[4].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 4) {
                  navigate(`/category?id=${categories[4].id}`);
                }
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 4 ? categories[4].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box>

            <Box
              bg={'transparent'}
              w={160}
              h={160}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 5 ? image_url + categories[5].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 5) {
                  navigate(`/category?id=${categories[5].id}`);
                }
              }}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 5 ? categories[5].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box> */}
            {/* <Box
              bg={'transparent'}
              w={160}
              h={160}
              style={{
                borderRadius: '6px',
                backgroundImage: `url(${
                  categories.length > 6 ? image_url + categories[6].image : ''
                })`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
              onClick={() => {
                if (categories.length > 6) {
                  navigate(`/category?id=${categories[6].id}`);
                }
              }}
              sx={{
                '&:hover': {
                  border: `2px solid ${theme.colors.brand[5]}`,
                },
                cursor: 'pointer',
              }}
            >
              <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                <Text
                  ta={'center'}
                  fz={16}
                  fw={700}
                  color={'black'}
                  p={6}
                  style={{ borderRadius: '30px' }}
                  bg={'rgba(255, 255, 255,0.6)'}
                >
                  {categories.length > 6 ? categories[6].title : ''}
                  <img
                    src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                    width={30}
                  />
                </Text>
              </Flex>
            </Box> */}
          </Grid>
        </Box>

        {/* Trending Products */}
        <Box my={10}>
          <Flex align="center" mt={10}>
            <IconTrendingUp color="red" size={'30px'} style={{ opacity: 0.5 }} />
            <Text fw={'700'} size="xl" ml={10} mb={0}>
              Trending Products
            </Text>
          </Flex>
          <Box mt={10}>
            <Carousel
              slideSize={isMobileScreen ? '50%' : '25%'}
              loop={true}
              align="start"
              slidesToScroll={1}
              slideGap={'sm'}
              withControls={isMobileScreen ? false : true}
              dragFree
            >
              {featuredProducts.map((e) => {
                return (
                  <Carousel.Slide>
                    <Box w={'100%'} mr={20} pb={20}>
                      <ProductCard object={e} addToCart={addToCart} />
                    </Box>
                  </Carousel.Slide>
                );
              })}
            </Carousel>
          </Box>
        </Box>

        {/* Bottom Banner */}
        <Box my={5}>
          <Grid>
            <Grid.Col span={12}>
              <CustomSlider slidesToShow={1}>
                {bottomBanners.map((e) => (
                  <div
                    onClick={() => {
                      if (categories.length > 0) {
                        if (e.product !== null) navigate(`/product?id=${e.product}`);
                      }
                    }}
                    onMouseOver={(e) => {
                      e.target.style.cursor = 'pointer';
                    }}
                  >
                    <Image
                      alt=""
                      src={image_url + e.image}
                      width={'100%'}
                      height={'100%'}
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ))}
              </CustomSlider>
            </Grid.Col>
          </Grid>
        </Box>
      </Container>

      <Modal
        centered
        opened={quickViewModalVisible}
        onClose={() => {
          setQuickViewModalVisible(false);
        }}
        size={'800px'}
        title="Product Title"
      >
        {/* Modal content */}
      </Modal>
    </div>
  );
}
