import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  Text,
  TextInput,
  Textarea,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/master.service';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';

const Feedback = () => {
  const theme = useMantineTheme();
  const [messageFeedback, setMessageFeedback] = useState(null);
  const [titleFeedback, setTitleFeedback] = useState(null);
  const [openedFeedback, setOpenedFeedback] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  useEffect(() => {
    api.get_feedbacks().then((res) => {
      console.log(res.data.data);
      setFeedbacks(res.data.data);
    });
  }, []);

  useEffect(() => {
    if (messageFeedback !== null || messageFeedback !== '') {
      setErrorMessage('');
    }
    if (titleFeedback !== null || titleFeedback !== '') {
      setErrorTitle('');
    }
  }, [messageFeedback, titleFeedback]);

  const handleDelete = (id) => {
    api.delete_feedback(id).then((res) => {
      console.log(res.data);
      if (res.data.success) {
        showSuccessToast({
          title: 'Success',
          message: res.data.message,
        });
        api.get_feedbacks().then((res) => {
          console.log(res.data.data);
          setFeedbacks(res.data.data);
        });
      } else {
        showErrorToast({
          title: 'Error',
          message: res.data.message,
        });
      }
    });
  };

  const sendFeedback = async () => {
    if (
      titleFeedback !== null &&
      titleFeedback !== '' &&
      messageFeedback !== null &&
      messageFeedback !== ''
    ) {
      let payload = {
        title: titleFeedback,
        description: messageFeedback,
      };
      await api.create_feedback(payload).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: res.data.message,
          });
          setTitleFeedback(null);
          setMessageFeedback(null);
          setOpenedFeedback(false);
          api.get_feedbacks().then((res) => {
            console.log(res.data.data);
            setFeedbacks(res.data.data);
          });
        } else {
          showErrorToast({
            title: 'Error',
            message: res.data.message,
          });
        }
      });
    }
    if (messageFeedback === null || messageFeedback === '') {
      setErrorMessage('Invalid Message');
    }
    if (titleFeedback === null || titleFeedback === '') {
      setErrorTitle('Invalid Title');
    }
  };

  return (
    <Box>
      <Grid>
        <Grid.Col span={12}>
          <Flex align={'center'} justify={'right'} w={'100%'}>
            <Button
              mt={20}
              variant="outline"
              onClick={() => {
                setOpenedFeedback(true);
              }}
            >
              Add Feedback / Suggest your products
            </Button>
          </Flex>
        </Grid.Col>
        {feedbacks.map((e, i) => (
          <Grid.Col span={12} key={i}>
            <Box
              bg={'white'}
              p={20}
              sx={{
                cursor: 'pointer',
                borderRadius: 3,
                border: '1px solid #eee',
              }}
            >
              <Flex justify={'space-between'} align={'center'}>
                <Text fw={600} size={16} mb={5}>
                  {e.title}
                </Text>
                <Flex>
                  <Tooltip label="Delete Feedback" color={theme.colors.brand[6]}>
                    <ActionIcon
                      onClick={() => {
                        handleDelete(e.id);
                      }}
                    >
                      <IconTrash size={15} />
                    </ActionIcon>
                  </Tooltip>
                </Flex>
              </Flex>
              <Text size={14}>{e.description}</Text>
            </Box>
          </Grid.Col>
        ))}
      </Grid>

      <Modal
        opened={openedFeedback}
        onClose={() => {
          setOpenedFeedback(false);
        }}
        styles={{ header: { borderBottom: '1px solid #eee' }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            <Flex align={'center'}>
              <Text ml={14} size={'16px'}>
                Add Feedback / Suggest your products
              </Text>
            </Flex>
          </Box>
        }
        size={'760px'}
        centered
      >
        <Box p={20}>
          <TextInput
            mt={5}
            label={'Title'}
            placeholder="Title"
            error={errorTitle}
            value={titleFeedback}
            onChange={(event) => setTitleFeedback(event.currentTarget.value)}
          />
          <Textarea
            mt={10}
            label={'Feedback'}
            placeholder="Message"
            minRows={4}
            error={errorMessage}
            value={messageFeedback}
            onChange={(event) => setMessageFeedback(event.currentTarget.value)}
          />
          <Flex align={'center'} justify={'right'}>
            <Button mt={20} mb={10} onClick={sendFeedback}>
              Send Feedback
            </Button>
          </Flex>
        </Box>
      </Modal>
    </Box>
  );
};

export default Feedback;
