import {
  ActionIcon,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Flex,
  Grid,
  Paper,
  SegmentedControl,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCheck, IconCircleCheck, IconPlus } from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import AddAddressModal from '../components/AddAddressModal';
import { api } from '../services/master.service';
import { showErrorToast, showSuccessToast } from '../utilities/Toast';
import { useNavigate } from 'react-router';
import { IconEdit } from '@tabler/icons';
import { Tick } from '../components/Tick';
import { CartContext } from '../App';
import { is_mobile_view } from '../config';

function CheckoutPage() {
  const navigate = useNavigate();

  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const [cart, setCart] = useState({});
  const { cartItemCount, setCartItemCount } = useContext(CartContext);

  const [addresses, setAddresses] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState('cod');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressModalOpened, setAddressModalOpened] = useState(false);
  const [checkoutSuccess, setCheckoutSuccess] = useState(false);
  const [editData, setEditData] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
      if (res.data.data.cartitem.length < 1) {
        navigate('/');
      }
    });
  }, []);

  const getData = () => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
    });
  };

  const getDataAddress = () => {
    api.get_addresses().then((res) => {
      console.log(res.data.data);
      setAddresses(res.data.data);
    });
  };

  const updateQty = (id, qty) => {
    if (qty <= 1) {
      removeItem(id);
    } else {
      api
        .update_cart({
          item_id: id,
          quantity: qty,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            showSuccessToast({
              title: 'Success',
              message: 'Cart Updated.',
            });
            getData();
          } else {
            showErrorToast({
              title: 'Error',
              message: 'Error updating cart.',
            });
          }
        });
    }
  };

  const removeItem = (id) => {
    api
      .remove_cart_item({
        item_id: id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: 'Cart Updated.',
          });
          getData();
        } else {
          showErrorToast({
            title: 'Error',
            message: 'Error updating cart.',
          });
        }
      });
  };

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const createOrder = (params) => {
    setIsLoading(true);
    api
      .create_order({
        order_type: paymentMethod,
        address_id: selectedAddress,
        payment_id: params?.payment_id || null,
        description: '',
        city_id: localStorage.getItem('city_id'),
      })
      .then((res) => {
        setIsLoading(false);
        console.log('Order Data :', res);
        if (res.data.success) {
          setCartItemCount(0);
          // getCartData();
          setCheckoutSuccess(true);
          // dispatch(getCartItems());
        } else {
          showErrorToast({ title: 'error', message: res.data.message });
        }
      });
  };

  const confirmPayment = async (payload) => {
    return await api.verify_payment(payload).then((res) => {
      if (res.data.success) {
        console.log(res.data);
        showSuccessToast({
          title: 'Success',
          message: res.data.message,
        });
        createOrder({
          payment_id: res.data.id,
        });
        // dispatch(getCartItems());
      } else {
        showErrorToast({ title: 'error', message: res.data.message });
      }
    });
  };

  const initiateRazorpay = () => {
    setIsLoading(true);
    let options;
    let user = JSON.parse(localStorage.getItem('user'));
    api
      .generate_order({
        amount: parseInt(+cart.total),
      })
      .then((res) => {
        console.log(res.data);
        options = {
          confirm_close: true,
          key: 'rzp_test_bEIwDjdXUHu2l5',
          currency: res.data.currency,
          amount: res.data.amount,
          name: 'Quzone Online Payment',
          description: 'Please make payment.',
          image: 'http://localhost:3000/static/media/logo.a645fa4ac453a1b954de.png',
          order_id: res.id,
          handler: (response) => {
            setIsLoading(false);
            console.log('Razorpay success', response);
            setCartItemCount(0);
            // await confirmPayment(response);
          },
          modal: {
            escape: false,
            confirm_close: true,
            ondismiss: function () {
              setIsLoading(false);
            },
          },
          prefill: {
            name: user.first_name,
            email: user.email,
            contact: user.username,
          },
        };
        const paymentObject = new window.Razorpay(options);

        paymentObject.on('payment.failed', function (response) {
          setIsLoading(false);
          console.log('Razorpay failure', response);
          paymentObject.close();
          showErrorToast({ title: 'error', message: response.error.description });
        });
        paymentObject.open();
      });
  };

  const placeOrder = () => {
    if (addresses?.length !== 0) {
      if (paymentMethod === 'cod') {
        createOrder();
      } else {
        initiateRazorpay();
      }
    } else {
      showErrorToast({ title: 'error', message: 'Address not found!!!' });
    }
  };

  useEffect(() => {
    loadScript('https://checkout.razorpay.com/v1/checkout.js');
  }, []);

  useEffect(() => {
    api.get_addresses().then((res) => {
      console.log(res.data.data);
      setAddresses(res.data.data);
      setSelectedAddress(res.data.data[0].id);
    });
  }, []);

  return (
    <div style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}>
      {checkoutSuccess ? (
        <Box px={30} mb={50}>
          <Box
            w={'100%'}
            mt={30}
            p={20}
            bg="white"
            sx={{
              border: `1px solid ${theme.colors.green[8]}`,
            }}
          >
            <Flex direction="column" align={'center'} justify={'center'}>
              {' '}
              <Tick size={50} style={{ display: 'inline-block' }} />
              <Text size={'34px'} fw={700} color="green">
                Thank you!
              </Text>
              <Text size={'md'} mt={10}>
                Your order has been successfully placed.
              </Text>
              <Button
                mt={10}
                variant="outline"
                onClick={() => {
                  navigate('/');
                }}
              >
                Continue Shopping
              </Button>
            </Flex>
          </Box>
        </Box>
      ) : (
        <div>
          <Container size={'xl'} fluid={isMobileScreen} px={isMobileScreen && 0}>
            <Box mb={isMobileScreen ? 14 : 24} px={10}>
              <Breadcrumbs separator="/">
                <Text size={14} color="dimmed">
                  Home
                </Text>
                <Text size={14}>Cart</Text>
                <Text size={14}>Checkout</Text>
              </Breadcrumbs>
            </Box>

            {/* Categories */}
            <Box p={10}>
              <Text size={'xl'} mb={20} fw={600}>
                Checkout
              </Text>
              <Grid>
                <Grid.Col span={12} md={9} lg={9}>
                  <Text fw={600} mb={10}>
                    Select Address
                  </Text>
                  <Grid>
                    {addresses.map((e) => (
                      <Grid.Col span={12} md={6} lg={4}>
                        <Box
                          bg={'white'}
                          p={20}
                          sx={{
                            cursor: 'pointer',
                            borderRadius: 3,
                            border: `1px solid ${
                              e.id === selectedAddress
                                ? theme.colors.dark[2]
                                : 'transparent'
                            }`,
                          }}
                          onClick={() => {
                            setSelectedAddress(e.id);
                          }}
                        >
                          <Flex align={'center'} justify={'space-between'}>
                            <Text fw={600} size={16} mb={5}>
                              {e.address_title}
                            </Text>
                            <Flex align={'center'} justify={'right'}>
                              <ActionIcon
                                onClick={() => {
                                  setAddressModalOpened(true);
                                  setIsEditing(true);
                                  setEditData(e);
                                }}
                              >
                                <IconEdit size={15} />
                              </ActionIcon>
                            </Flex>
                          </Flex>
                          <Text size={14}>{e.first_name}.</Text>
                          <Text size={14}>{e.phone_no}</Text>
                          <Text size={14}>{e.address_line_1}</Text>
                          <Text size={14}>{e.address_line_2}</Text>
                          <Text size={14}>
                            {e.city}, {e.state}, {e.country}, {e.pincode}.
                          </Text>
                        </Box>
                      </Grid.Col>
                    ))}
                  </Grid>
                  <Button
                    mt={20}
                    variant="outline"
                    leftIcon={<IconPlus size={14} />}
                    onClick={() => {
                      setIsEditing(false);
                      setEditData({});
                      setAddressModalOpened(true);
                    }}
                  >
                    Add New Address
                  </Button>
                </Grid.Col>
                <Grid.Col span={12} md={3} lg={3}>
                  <Paper mt={10} p={18} w={'100%'} radius={'sm'} shadow="sm">
                    <Text
                      size={16}
                      fw={600}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      Payment Method
                    </Text>
                    <Box>
                      {/* <SegmentedControl
                        color="primary"
                        w={'100%'}
                        value={paymentMethod}
                        onChange={setPaymentMethod}
                        data={[{ label: 'COD', value: 'cod' }]}
                      /> */}
                      <Button
                        w={'100%'}
                        variant={paymentMethod === 'cod' ? 'filled' : 'light'}
                        onClick={() => {
                          //paymentMethod === 'cod' ? setPaymentMethod('') : setPaymentMethod('cod');
                        }}
                      >
                        {'COD (Cash On Delivery)'}
                      </Button>
                    </Box>
                  </Paper>
                  <Paper mt={10} p={18} w={'100%'} radius={'sm'} shadow="sm">
                    <Text
                      size={16}
                      fw={600}
                      style={{
                        paddingBottom: 10,
                      }}
                    >
                      Order Summary
                    </Text>
                    <Box mt={20}>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          borderBottom: '1px solid #eee',
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={14}>
                          Subtotal
                        </Text>
                        <Text align="right" size={14}>
                          ₹ {cart?.sub_total}
                        </Text>
                      </Flex>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          borderBottom: '1px solid #eee',
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={14}>
                          Tax Total ( Inclusive )
                        </Text>
                        <Text align="right" size={14}>
                          ₹ {cart?.tax_total}
                        </Text>
                      </Flex>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          borderBottom: '1px solid #eee',
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={14}>
                          Shipping
                        </Text>
                        <Text align="right" size={14}>
                          ₹ {cart?.delivery}
                        </Text>
                      </Flex>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          borderBottom: '1px solid #eee',
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={14}>
                          Packaging
                        </Text>
                        <Text align="right" size={14}>
                          ₹ {cart?.packaging}
                        </Text>
                      </Flex>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          borderBottom: '1px solid #eee',
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={14}>
                          Total Discount
                        </Text>
                        <Text align="right" size={14}>
                          ₹ {cart?.discount_total}
                        </Text>
                      </Flex>
                      <Flex
                        justify={'space-between'}
                        mb={20}
                        style={{
                          paddingBottom: 15,
                        }}
                      >
                        <Text align="left" size={16} fw={600}>
                          Total
                        </Text>
                        <Text align="right" size={16} fw={600}>
                          ₹ {cart?.total}
                        </Text>
                      </Flex>
                    </Box>
                    <Button
                      w={'100%'}
                      rightIcon={<IconCheck size={17} />}
                      color="green"
                      loading={isLoading}
                      onClick={() => {
                        placeOrder();
                      }}
                    >
                      Place Your Order
                    </Button>
                  </Paper>
                </Grid.Col>
              </Grid>
            </Box>
          </Container>

          <AddAddressModal
            opened={addressModalOpened}
            isEditing={isEditing}
            editData={editData}
            onClose={() => {
              setAddressModalOpened(false);
              getDataAddress();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default CheckoutPage;
