import { Box, Button, Flex, Grid, Text, TextInput, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/master.service';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import ViewOrderModal from '../../components/ViewOrderModal';
import moment from 'moment';
import Joi from 'joi';
import { joiResolver, useForm } from '@mantine/form';
import { is_mobile_view } from '../../config';

function AccountDetails() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();

  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePwd, setIsChangePwd] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    setUser(user);
    form.setValues({
      first_name: user.first_name,
      email: user.email,
      username: user.username,
    });
  }, []);

  const schema = Joi.object({
    first_name: Joi.string().required(),
    email: Joi.string().required(),
    username: Joi.string().required(),
  });

  const schema2 = Joi.object({
    old_password: Joi.string().required(),
    new_password: Joi.string().min(3).max(15).required().label('Password'),
    confirm_password: Joi.any()
      .equal(Joi.ref('new_password'))
      .required()
      .label('Confirm password')
      .messages({ 'any.only': '{{#label}} does not match' }),
  });

  const form = useForm({
    initialValues: {
      first_name: '',
      email: '',
      username: '',
    },
    validate: joiResolver(schema),
  });

  const passwordForm = useForm({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    validate: joiResolver(schema2),
  });

  const editDetails = async (values) => {
    await api.edit_user(values).then((res) => {
      setIsLoading(false);
      console.log(res.data);
      if (res.data.success) {
        showSuccessToast({
          title: 'Success',
          message: 'Successfully edited details.',
        });
      } else {
        showErrorToast({
          title: 'Error',
          message: 'Error editing details.',
        });
      }
    });
  };

  const changePassword = async (values) => {
    await api
      .change_password({
        username: user.username,
        old_password: values.old_password,
        new_password: values.new_password,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: 'Successfully changed password.',
          });
          passwordForm.reset({});
        } else {
          showErrorToast({
            title: 'Error',
            message: res.data.message,
          });
        }
      });
  };

  return (
    <>
      {isChangePwd ? (
        <></>
      ) : (
        <>
          <Flex align={'center'} justify={'space-between'}>
            <Text size={'lg'} fw={600}>
              Account Details
            </Text>
            <Button
              variant="outline"
              onClick={() => {
                setIsChangePwd(true);
              }}
            >
              Change Password
            </Button>
          </Flex>
          <Box mt={20}>
            <Box
              p={25}
              mb={15}
              bg={'white'}
              sx={{
                border: '1px solid #eee',
                borderRadius: 3,
              }}
            >
              <form
                onSubmit={form.onSubmit((values) => {
                  editDetails(values);
                })}
              >
                <Grid gutter={'lg'}>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Enter First Name"
                      label="First Name"
                      {...form.getInputProps('first_name')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={6} lg={6}>
                    <TextInput
                      placeholder="Enter Email"
                      label="Email address"
                      {...form.getInputProps('email')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={6} lg={6}>
                    <TextInput
                      disabled
                      placeholder="Enter Username"
                      label="Username"
                      {...form.getInputProps('username')}
                    />
                  </Grid.Col>
                </Grid>
                <Button mt={20} w={'100%'} type="submit">
                  Edit Account Details
                </Button>
              </form>
            </Box>
          </Box>
        </>
      )}
      {isChangePwd ? (
        <>
          <Flex align={'center'} justify={'space-between'}>
            <Text size={'lg'} fw={600}>
              Change Password
            </Text>
            <Button
              variant="outline"
              onClick={() => {
                setIsChangePwd(false);
              }}
            >
              Cancel
            </Button>
          </Flex>
          <Box mt={20}>
            <Box
              p={25}
              mb={15}
              bg={'white'}
              sx={{
                border: '1px solid #eee',
                borderRadius: 3,
              }}
            >
              <form
                onSubmit={passwordForm.onSubmit((values) => {
                  changePassword(values);
                })}
              >
                <Grid gutter={'lg'}>
                  <Grid.Col span={12}>
                    <TextInput
                      placeholder="Enter Old password"
                      label="Old password"
                      type="password"
                      {...passwordForm.getInputProps('old_password')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={6} lg={6}>
                    <TextInput
                      placeholder="Enter New Password"
                      label="New password"
                      type="password"
                      {...passwordForm.getInputProps('new_password')}
                    />
                  </Grid.Col>
                  <Grid.Col span={12} md={6} lg={6}>
                    <TextInput
                      placeholder="Enter Confirm Password"
                      label="Confirm Password"
                      type="password"
                      {...passwordForm.getInputProps('confirm_password')}
                    />
                  </Grid.Col>
                </Grid>
                <Button mt={20} w={'100%'} type="submit">
                  Change Password
                </Button>
              </form>
            </Box>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default AccountDetails;
