import { List, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks';
import React from 'react'
import { is_mobile_view } from '../config';

const TermsOfUse = () => {
    let isMobileScreen = useMediaQuery('(max-width: 768px)');
    isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
    return (
        <div style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px', padding: "20px" }}>
            <Text color='black' fw={700} fz={"lg"} td={"underline"} mb={15}>
                Terms of Use - Quzone
            </Text>
            <Text color='black' fw={400} fz={"sm"} bg={"#ffff00"} w={"fit-content"} mb={10}>
                Last Updated: 9th Jan, 2024
            </Text>
            <Text color='black' fw={400} fz={"sm"} mb={15} ta={"justify"}>
                This documentis an electronic record and published in accordance with the provisions of the Information Technology Act 2000 and the rules there under and generated by a computer system and does not require any physical or digital signatures.
            </Text>
            <List listStyleType={"upper-alpha"} pr={20}>
                <List.Item mb={15} fz={"sm"} ta={"justify"}>
                    This website&lsquo; www.quzone.in (
                    <strong>
                        Website
                    </strong>
                    ) and mobile application &lsquo;Quzone&rsquo; (
                    <strong>
                        App
                    </strong>
                    ) (Website and App collectively referred to as the &ldquo;
                    <strong>
                        Platform
                    </strong>
                    &rdquo;)is operated and managed by the Company. The term{" "}
                    <strong>
                        Company
                    </strong>{" "}
                    shall collectively and individually refer to each company which operates and manages the Platform with respect to the respective cities. You can access the complete Company details
                    {" "}- branch ofc 5A/87A Caldwell colony, 1st Street Thoothukudi 628008
                    .
                </List.Item>
                <List.Item mb={15} fz={"sm"} ta={"justify"}>
                    These terms of use (
                    <strong>
                        &ldquo;Terms&rdquo;
                    </strong>
                    ) govern Your use of the Platform, and for the purpose of these Terms the Company may wherever context so require, be also referred to as, &ldquo;
                    <strong>
                        Quzone
                    </strong>
                    &rdquo;, "
                    <strong>
                        We
                    </strong>
                    "
                    <strong>
                        ,
                    </strong>
                    "
                    <strong>
                        Us
                    </strong>
                    ", or "
                    <strong>
                        Our
                    </strong>
                    " and the terms &ldquo;
                    <strong>
                        You
                    </strong>
                    &rdquo;, &ldquo;
                    <strong>
                        Your
                    </strong>
                    &rdquo; or &ldquo;
                    <strong>
                        User
                    </strong>
                    &rdquo; refer to user of the Platform. We value the trust you place in us. That&rsquo;s why; we maintain reasonable security standards for securing the transactions and your information.

                </List.Item>
                <List.Item mb={15} fz={"sm"} ta={"justify"}>
                    Please read these Terms carefully before you use the services. If you do not agree to these Terms, You may not use the Services (defined hereinafter) on the Platform, and We request You to uninstall the App. By even merely using/accessing the Platform, You shall be contracting with Quzone and You signify Your acceptance to these Terms and other Quzone policies (including but not limited to the cancellation &amp; refund policy of the Company and published privacy policy (&lsquo;Privacy Policy&rsquo;)) as posted on the Platform and amended from time to time, which takes effect on the date on which You use the Platform, and thereby create a legally binding arrangement to abide by the same. Further, by furnishing Your personal information, You consent to Quzone accessing Your personal information for the purpose of rendering the Services (as defined below), You also agree that You are interested in availing the Services through the Platform in accordance with these Terms.

                </List.Item>
                <List.Item mb={15} fz={"sm"} ta={"justify"}>
                    Quzone retains an unconditional right to modify or amend these Terms. You can determine when these Terms were last modified by referring to the &ldquo;Last Updated&rdquo; legend above. You can access the latest version of these Terms at any given time on the Platform. You should regularly review the Terms on the Platform. Your continued use of and access to the Platform shall be Your consent to such changes. In the event the modified Terms are not acceptable to You, You should discontinue accessing the Platform.

                </List.Item>
            </List>
            <Text color='black' fw={700} fz={"md"} ta={"justify"}>
                1. Services
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.1. You acknowledge that the Platform allows you to avail the following services including:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.1.1. a personal, limited, non-exclusive, non-transferable, and revocable privilege to access and use the Platform for the purposes of purchasing select products including but not limited to consumer goods such as grocery, fresh vegetables &amp; fruits, etc. (collectively, &ldquo;Product(s)&rdquo;) from the Company, sold on a business to consumer (B2C) basis for personal consumption and not for resale;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.1.2. Accessing services provided by the Company, through the Platform, including but not limited to last mile delivery of Products; and
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.1.3. Promptly and efficiently responding to your queries or issues relating to Your Account and the services availed by you, using the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.2. The aforementioned services shall be collectively referred to as the &ldquo;
                <strong>
                    Services
                </strong>
                &rdquo;. The said revocable privilege to access does not include/permit resale or commercial use of the Platform or its Content (as defined below), in any manner. The Company may, at its discretion, add, modify, or remove any of the Products and/or Services listed above from time to time without notice. The Company may also make applicable, at its discretion, additional terms and conditions specific to any category or section of Products in addition to these Terms and Your purchase of any of such category or section of Products shall also be governed by such additional terms and conditions. The Company reserves the right to provide the Services in any area or city in India, as may be determined by the Company in its sole discretion. Before applying or registering for availing any of the Services, we request you to please check if the Services are available in Your area or city. The Company provides last mile delivery of Products through the third party pick-up and delivery service providers, who will be providing the delivery services for a service fee.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.3. The Company does not: (i) adopt any &lsquo;unfair trade practices&rsquo; either on the Platform or otherwise with respect to the Products and Services; (ii) discriminate between Users of the same class or make any arbitrary classification of the Users; and (iii) discriminate between the third-party delivery service providers.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                1.4. The Products that are sold would be as per the requirements of The Standard of Weights and Measures Act, 1976.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                2. Eligibility to Use
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                2.1. The Services are not available to minors i.e., persons under the age of 18 (eighteen) years, undercharged insolvent, or to any Users who are not competent to enter into a contract under the Indian Contract Act, 1872, unless otherwise provided hereunder. You affirm that you are either more than 18 years of age, or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties provided in the Terms of Use and to abide by and comply with the same. You acknowledge that the Company has given you a reasonable opportunity to review these Terms of Use and that you have agreed to them.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                2.2. You will not discriminate against third-party delivery service providers based on race, religion, caste, creed, national origin, disability, sexual orientation, sex, marital status, gender identity, age or any other metric which is deemed to be unlawful under applicable laws. Any credible proof of such discrimination, including any refusal to receive goods or services based on the above metrics, whether alone or in conjunction with any other metric, shall render you ineligible, leading to suspension of Services. You will not have any claim towards, and we will not have any liability towards any suspension which is undertaken as a result of the aforementioned event.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                3. User Account, Password, and Security
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                3.1. In order to access the Services on the Platform, You will have to register and create an account on the Platform by providing details as may be required in the Privacy Policy (&ldquo;Account&rdquo;).
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                3.2. You are solely responsible for the information You provide to Us. You shall ensure and confirm that the Account information and all information provided by You is complete, accurate and up-to-date. If there is any change in the Account information, or if any information is found to be incomplete or incorrect, You shall promptly update Your Account information on the Platform or request Quzone for information revision or update. If the information provided by You is untrue, inaccurate, unauthorized, not current, or incomplete (or becomes untrue, inaccurate, not current, or incomplete), or Quzone reserves the right to refuse any and all the Services, if Quzone has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete.. Quzone reserves the express right to refuse access to the Services at any time without notice for Your failure to abide by the Terms asset forth herein or to comply with applicable laws .
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                3.3. You will be responsible for the confidentiality of the Account information provided and fully responsible for all activities that take place on Your Account. You agree to immediately notify Quzone of any unauthorized access of Your Account. You may be held liable to pay damages for losses caused due to unauthorized use of Your Account as a result of Your failure to keep Your Account information secure and confidential.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                4. Payment related Information
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.1. The information relating to the accepted payment methods on the Platform shall be displayed during the purchasing process.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.2. To the extent permitted by applicable law and subject to the Privacy Policy, You acknowledge and agree that the Company may use certain third-party vendors and service providers, including payment gateways, to process payments and manage payment card information. The Company can ensure such third-party vendors and service providers possess necessary licenses from the appropriate authority.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.3. In order to avail the Services, You undertake to use and provide valid bank details or other details required for facilitating payment towards the Services (&ldquo;Payment Details&rdquo;). By providing the Payment Details, You represent, warrant, and covenant that: (a) You are legally authorized to provide such Payment Details; (b) You are legally authorized to perform payments using such Payment Details; and(c) such action does not violate the terms and conditions applicable to Your use of such Payment Details or applicable law. You may add, delete, and edit the Payment Details You have provided from time to time through the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.4. Except to the extent otherwise required by applicable law, the Company is not liable for any payments authorized through the Platform using Your Payment Details. Particularly, the Company is not liable for any payments that do not complete because: (a) Your bank account or any other payment did not contain sufficient funds to complete the transaction; (b) You have not provided the Company with correct Payment Details; (c) Your payment card has expired; or (d) circumstances beyond the Company&rsquo;s reasonable control (such as, but not limited to, power outages, interruptions of cellular service, or any other interferences from an outside force) which prevent the execution of the transaction.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.5. The Company shall not be responsible for any unauthorised transactions conducted on the Platform using Your Payment Details. The Company shall not be obligated to refund any money to You in such instances.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                4.6. The payment facility provided by Company is neither a banking nor a financial service but is merely a facility providing an electronic, automated online electronic payment system, and receiving payment on delivery for the transactions on the Platform using the existing authorized banking infrastructure and card payment gateway networks, as may be applicable. For some payment methods, Your issuer may charge You certain fees, such as transaction fees or other fees, relating to the processing of Your transaction.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                5. Prices of Products
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                5.1. The prices of each of the Products may vary due to various factors and You may check the priceon the Platform before placing an order. By placing an order You agree to pay the prices mentioned therein. All the Products listed on the Platform will be sold at Indian Rupees either at Maximum Retail Price (MRP)(inclusive of all taxes) or at a discounted price unless otherwise specified. The prices of the Products may be modified from time to time. The Company endeavours to make available the Products at the best prices as possible on the Platform.There can be an unintended error with respect to the Prices and other information of the Products. You may bring it to Our notice of such errors and we shall effect necessary corrections not later than 24 hours from the time of delivery.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                5.2. The Users will be informed about all the charges, fees, and costs (including, delivery fee) that may be levied on the purchase of the Products on the Platform at the checkout page during a transaction. The Company does not manipulate the price of any Products and/or Services offered on the Platform.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                6. Delivery, delivery fee, delivery fee and delivery time
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                6.1. The Company shall provide delivery of the Products during such time period as communicated to You through the Platform. The Company endeavors to show the estimated delivery time for every order, however, the Company does not guarantee the delivery within the said time, since the exact delivery time of each order may vary due to various factors such as availability of third party delivery service providers, demand, traffic and weather conditions, a force majeure event, etc,. Further, You can check the estimated time of arrival (ETA), when You go to the homepage of the App even before placing an order, to ensure that You are aware of the ETA before proceeding with placing an order. You agree that the Company reserves the right to charge You, fee for rain, peak hours and/or very high demand in addition to the delivery fee towards the delivery of the orders, which you can see on the view bill section before checkout page on the App.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                6.2. The delivery of the Products will be made to the delivery address specified by You while placing the order for the Products on the Platform. You are solely responsible for providing a complete and correct delivery address and, if applicable, further instructions for delivery. The Company shall not be responsible for any delay in delivering the order placed by You on the Platform caused due to the incorrect or incomplete address provided by You. In the event of the delivery partner is unable to deliver the ordered goods for the reason that the address is incorrect or the contact number of the purchaser is either wrong or not reachable or when there is no specific instruction on delivery, the payment collected, if any, shall be refunded after adjusting the cost of delivery (in the event of delivery partner making an attempt to deliver the ordered Products. In the event of any unforeseen circumstances like fire, unavailability of Product, floods, earthquake, or other natural calamities or catastrophes, the entire amount, if any, received for the ordered Products would be refunded as the same could not be delivered.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                6.3. The delivery service is provided through third-party delivery service providers. The third-party delivery service providers will ordinarily make deliveries when an appropriate person is able to receive the order at the address provided by You. If You request to leave a delivery unattended at Your address, You expressly disclaim the Company from all the liabilities which may arise by virtue of the Products being left unattended for a period of time. This includes but is not limited to theft, tampering, contamination, and the result of any change in temperature of the items which need to be kept chilled or frozen.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                6.4. In the event You opt to make payment for the Products by &lsquo;Cash on Delivery (COD)&rsquo; mode, the third-party delivery service providers shall have the right to refuse delivery of the ordered Products to You if You fail to make the complete payment and the Company shall treat such order as cancelled order and shall not be liable for any losses or damage that may arise on account of such non - delivery to You. The Company shall reserve the right to charge You, the delivery fee for such cancelled order and recover the same, at its sole discretion towards the cost incurred on such delivery attempt.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                7. Returns, Cancellations, and Refunds:
            </Text>
            <Text color='black' fw={700} fz={"sm"}>
                7.1 Returns
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.1 You may return the Product in an order, subject to the following, conditions:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.1.1 Wrong item being delivered other than what You had ordered in an order or
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.1.2 Items substantially damaged or deteriorated in quality at the time of delivery. You agree that You shall give Us all the requisite proofs including but not limited to images of Products having issues.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.2 You shall check the Products upon delivery and initiate exchange or return with the requisite proofs on the Platform. You may exchange or return the product by contacting Our customer care also. IT IS HEREBY CLARIFIED THAT NO EXCHANGE OR RETURNS SHALL BE ACCEPTED IF THE PRODUCT PACKAGING IS OPENED OR PRODUCT ISUSED/CONSUMED EITHER IN PART OR OTHERWISE.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.3 You may request exchange or return the Products, purchased from us provided the Products are sealed/unopened/unused and in original condition and on the same day of delivery. Please note the requests for exchange or returns will not be accepted from the day following the day when we had delivered the Product/s to You. We may request You to dispose of the Products for which We have processed a refund.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.1.4. There shall be a designated return point in each notified area, the details of which will be provided in our website. For return of any products owing to the reasons stated in 7.1.1, the same may be replaced once returned by You or the appropriate person who received the order.
            </Text>
            <Text color='black' fw={700} fz={"sm"}>
                7.2 Cancellations:<Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                    You may cancel an order without charge at any time before the Company accepts the order. You cannot cancel the order post acceptance of order by the Company. The Company may cancel an order (in full or partially) for the reasons including shortage or unavailability of certain Products or force majeure events. In case the Company cancels any order, You will not be charged for such cancellations, and the Company will refund You for any payment already made.
                </Text></Text>
            <Text color='black' fw={700} fz={"sm"}>
                7.3 Refunds: <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                    Please be informed that when You opt to cancel or return the Products, upon our verification of the Products and the documents relating thereto, the refund amount for such Products which are eligible for return as per these Terms, will be processed within a period of seven (7) business days from the date of us verifying and confirming the refund request. Your refund will be processed only when the conditions as may be stipulated by Us are fulfilled. The refund amount will be credited to the source account. You acknowledge that after initiation of refund, it may take additional time for Your refund to reflect in Your account which is subject to Your financial institution or payment gateway service provider terms and conditions
                </Text></Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                7.4 The terms for acceptance of returns, cancellation and refunds shall be subject to reasonable additional conditions and shall be communicated to the User, from time to time, through the push notifications on the Platform, or any other mode of communication as specified in these Terms or as determined by the Company.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                8. User care:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.1 The warranty and guarantee, if any, applicable to the Products shall be that of the manufacturer and You may directly reach out to the manufacturer through the customer care details made available on the Product packaging. The Company is merely a reseller and disclaims any liability with respect to the manufacturing defects, quality, taste, performance of the Products sold.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.2 You agree and understand that the Product images are representation of the Product and not actual image of the Product sold to You and You shall read the physical product label for the calorific and nutrition value, using instructions, batch, manufacture date, content, weight, manufacturer and the customer care details, as may be relevant, before consumption of the Products. WHILE EVERY REASONABLE EFFORT IS MADE TO MAINTAIN ACCURACY OF INFORMATION ON THE PLATFORM, ACTUAL PRODUCT PACKAGING, MAY CONTAIN MORE AND/OR DIFFERENT INFORMATION THAN WHAT IS SHOWN ON THE PLATFORM. IT IS RECOMMENDED TO REFER THE INFORMATION PRESENTED ON THE ACTUAL PRODUCT PACKAGING
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.3 Company reserves its right to refuse to process transactions by Users with a prior history of questionable transactions including without limitation breach of any agreements by User with Company or breach/violation of any law or any charges imposed by bank or breach of any policy without giving any reasons. Company may do such checks as it deem fit before approving the User's order for security or other reasons at the discretion of Company. As a result of such checks, if the Company is not satisfied with the credibility of the User or genuineness of the transaction, it will have the right to reject such an order. Company may delay the dispatch or cancel any transaction at its sole discretion, if it is suspicious of any User's authenticity or activity or if the User is conducting high transaction volumes, to ensure safety of the transaction.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.4 Beware of fraud:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.4.1. Please do not share Your debit/credit card number, CVV number, OTP, UPI/ATM pin and other sensitive information with anyone claiming to be a Company representative. Company or its authorized representatives will NEVER ask You to share the aforesaid details. Beware of fraudsters and please report incidents immediately to Your bank, the nearest police station and at
                {" "}<a href="https://cybercrime.gov.in/">
                    https://cybercrime.gov.in/
                </a>
                .
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.4.2. For assistance on a Quzone order or refund related issues, click on the &lsquo;Get Help&rsquo; section on the App.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                8.4.3.Please exercise caution to verify the portals/website links claiming to be Quzone or a lookalike or a payment link shared over social media or a social messaging apps claiming to be Quzone discounts or offers and proactive calls from unauthorized numbers requesting for personal/financial information.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                9. Use of Platform
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.1. Subject to compliance with the Terms, the Company hereby grants You a personal, non-exclusive, non-transferable, limited, revocable privilege to access and use the Platform. You agree to use the Platform only: (a) for purposes that are permitted by the Terms; (b) in accordance with any applicable law, regulation or generally accepted practices or guidelines; and (c) for availing the Services through the Platform. You agree not to engage in activities that may adversely affect the use of the Platform by the Company and/or other Users.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.2. You agree that the Platform or any portion of the Platform shall not be reproduced, duplicated, copied, sold, resold or otherwise exploited for commercial purposes.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.3. You agree to not frame or utilize the framing techniques to enclose any trademark, logo or any other proprietorship information of the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.4. You agree not to access (or attempt to access) the Platform by any means other than through the interface that is provided by the Company. You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or Content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5. Further, You undertake not to host, display, upload, modify, publish, transmit, store, update or share any information that:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.1. belongs to another person and to which the User does not have any right;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.2. is defamatory, obscene, pornographic, pedophilic, invasive of another's privacy, including bodily privacy, insulting, or harassing on the basis of gender, libelous, racially, or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.3. is harmful to child;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.4. infringes any patent, trademark, copyright, or other proprietary rights;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.5. violates any law for the time being in force;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.6. impersonates another person;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.7. threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting other nation;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.8. Contains software virus or any other computer code, file or program designed to interrupt, destroy, or limit the functionality of any computer resource;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.9. is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity, or agency for financial gain or to cause any injury to any person;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.10. disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked sites;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.11. violate the Terms contained herein or elsewhere; and
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.5.12. reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.6. You shall solely be responsible for maintaining the necessary computer equipment, gadgets and internet connections that may be required to access, use, and transact on the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.7. You understand and acknowledge that by using the Platform or any of the Services, You may encounter Content that may be deemed by some Users to be offensive, indecent, or objectionable, which Content may or may not be identified as such. You agree to use the Platform and any Services at Your sole risk and that to the fullest extent permitted under applicable law. The Company shall have no liability to You for Content that may be deemed offensive, indecent, or objectionable to You.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.8. You are prohibited to alter or modify the contents of the website.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.9. You are prohibited to access the Company website, app and its contents by any other technology than by directly accessing the webpage or app.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                9.10. You are restricted from interfering, causing disruption or circumventing with the features of Company website and app which are related to the security.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                10. Intellectual Property Rights
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                10.1 The Platform and the processes, and their selection and arrangement, including but not limited to, all text, videos, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (and any combinations thereof) (collectively, the &ldquo;Content&rdquo;) on the Platform is licensed to the Company and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, patent and trademark laws, and various other intellectual property rights.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                10.2 The trademarks, logos and service marks displayed on the Platform (&ldquo;Marks&rdquo;) are the licensed property of the Company or owned by third parties. You are not permitted to use the Marks without the prior consent of the Company or the relevant third party (which is the owner of the Marks) respectively. Access or use of the Platform does not authorize any party to use trademarks, logo, or any other mark in any manner.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                10.3 The Company disclaims to hold any right, title, or interest in and to the intellectual property rights arising out of or associated with the Products.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                10.4 References on the Platform of any name, mark, services or products of third parties has been provided after the express consent of such third party for Your convenience, and in no way constitutes an endorsement, sponsorship or recommendation, whether express or implied.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                11. Disclaimer of Warranties &amp; Liability
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.1 You expressly understand and agree that, to the maximum extent permitted by applicable law:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.1.1 The Platform and other Content are provided by the Company on an &ldquo;as is&rdquo; basis without warranty of any kind, express, implied, statutory, or otherwise, including the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, the Company makes no warranty that the Platform or Services will meet Your requirements, or Your use of the Platform will be uninterrupted, timely, secure, or error-free. No advice or information, whether oral or written, obtained by You from the Company shall create any warranty not expressly stated in the Terms.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.1.2 Company will not be liable for any loss that You may incur as a consequence of unauthorized use of Your Account or Account information by anyone in connection with the Platform either with or without Your knowledge.However, you may be liable for the losses caused to Company due to such unauthorized usage or access.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.1.3 Company has endeavored to ensure that all the information on the Platform is correct, but the Company neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information regarding the Services or otherwise. The Company shall not be responsible for the delay or inability to use the Platform or related functionalities, the provision of or failure to provide functionalities, or for any information, software, functionalities, and related graphics obtained through the Platform, or otherwise arising out of the use of the Platform, whether based on contract, tort, negligence, strict liability or otherwise. Further, the Company shall not be held responsible for non-availability of the Platform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to technical reasons or for any reason beyond the Company&rsquo;s reasonable control.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.2 While the Company has made best efforts to display the colours of the Product image on the Platform as accurately as possible. However, the actual colours of the Products You see will depend on Your monitor or device, and the Company does not provide any guarantee in respect of such display and will not be responsible or liable for the same.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.3 The Company makes no representation that the Content on the Platform is appropriate to be used or accessed outside the Republic of India. Any Users who use or access the Platform from outside the Republic of India, do so at their own risk and are responsible for compliance with the laws of such jurisdiction.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                11.4 The Terms do not constitute, nor may the Terms be used for or in connection with any promotional activities or solicitation by anyone in any jurisdiction in which such promotional activities or solicitation are not authorized or to any person to whom it is unlawful to promote or solicit.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                12. Indemnification and Limitation of Liability
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                12.1 You agree to indemnify, defend and hold harmless Quzone and its affiliates including but not limited to its (and its affiliates&rsquo;) officers, directors, consultants, agents, representatives and employees; and its third party partners (&ldquo;Indemnitees&rdquo;) from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including reasonable legal fees) asserted against or incurred by the Indemnitees that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms and/or the Privacy Policy. Further, You agree to hold the Indemnitees harmless against any claims made by any third party due to, or arising out of, or in connection with, Your use of the Platform, any misrepresentation with respect to the data or information provided by You, Your violation of the Terms and/or the Privacy Policy, Your violation of applicable laws, or Your violation of any rights of another, including any intellectual property rights.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                12.2 In no event shall the Company and its directors, officers, partners, consultants, agents, and employees and its third party partners, be liable to You or any third party for any special, incidental, indirect, consequential, or punitive damages whatsoever, arising out of or in connection with Your use of or access to the Platform or Content on the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                12.3 The limitations and exclusions in this Section apply to the maximum extent permitted by applicable laws.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                13. Violation of these Terms
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                13.1 You agree that any violation by You of these Terms will likely cause irreparable harm to the Company, for which monetary damages would be inadequate, and You consent to the Company obtaining any injunctive or equitable relief that they deem necessary or appropriate in such circumstances. These remedies are in addition to any other remedies that the Company may have at law or in equity.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                14. Suspension and Termination
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                14.1 The Terms will continue to apply until terminated by either You or the Company as set forth below. If You object to the Terms or are dissatisfied with the Platform, You may (i) close Your Account on the Platform; and/or (ii) stop accessing the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                14.2 The Company may disable Your access or block Your future access to the Platform or suspend or terminate Your Account if it believes, in its sole and absolute discretion, that You have violated any term of these Terms or the Privacy Policy or in any way otherwise acted unethically. Notwithstanding anything in this Clause, all terms which by their nature are intended to survive such termination, will survive indefinitely unless and until the Company chooses to terminate them.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                14.3 Any such termination under clause 14.1 and 14.2 above shall not cancel Your obligation to pay for a Product purchased on the Platform, or any other obligation which has accrued, or is unfulfilled and relates to the period, prior to termination.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                14.4 You shall be liable to pay any fees or charges, if applicable in respect of the Services until the date of termination by either party whatsoever.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                14.5. If you constantly and habitually cause infringement or violation of the Terms of Use &amp; Privacy Policy, the Company may terminate/ restrict Your access to the user account and/or access to the Company website or app without prior notice.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                15. Governing Law and Jurisdiction
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                15.1 These Terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts at Mumbai, India.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                16. Grievance Redressal Mechanism
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                16.1 Grievance Handling:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                16.1.1 For any order related issue, You may first reach out to Us via chat support on the App for real time basis resolution.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                16.1.2 You may also write to Us at support@Quzone.in and We will strive to resolve Your order related grievance within the timelines prescribed under applicable laws.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                16.1.3 If You still have any grievances, or complaints or concerns with respect to the Platform or order or are not satisfied with the resolution, the Content, or the Services, You can contact the designated Grievance cum Nodal Officer of the Company, can be accessed
                {" "}<a href="#">
                    here
                </a>
                . Details of the Grievance cum Nodal Officer is provided in compliance of (1) Information Technology Act, 2000 and rules made there under, and (2) Consumer Protection (E-Commerce) Rules 2020, as amended time to time.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                16.2 The Grievance/ Redressal Officer of the Company shall endeavour to acknowledge the User grievances, or complaints or concerns with respect to the Platform, the Content, or the Services, within 48 hours of receipt of the same and shall endeavour to redresses the same at the earliest and in no event later than 30 (thirty) days of receipt of such request. By lodging a complaint or grievance, You agree to provide complete support to the Grievance Officer and such reasonable information as may be sought by them from You.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                17. Communications
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                17.1 You hereby expressly agree to receive communications by way of SMS, telephone or VOIP calls, messaging app like WhatsApp on the registered mobile phone number /or electronic communications like e-mails from the Company and other third parties duly authorised by the Company. You hereby expressly consent to the monitoring and recording, by the Company of any and all communications between You and the Company or its agents, employees, consultants, contractors, or representatives of the Company or of their authorised partners, and such monitoring or recording waives any further notice or consent requirement under the applicable laws.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                17.2 You can unsubscribe or opt-out from receiving communications from the Company. In which case, the Company will only send communications solely required for the purposes of availing the Services by You.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                18. General Provisions
            </Text>
            <Text color='black' fw={700} fz={"sm"} ta={"justify"}>
                18.1 Notice:
                <Text color='black' fw={400} fz={"sm"}>
                    All notices from the Company will be served by email to Your registered email address or by messaging app on the registered mobile phone number or by general notification on the Platform.
                </Text>
            </Text>
            <Text color='black' fw={700} fz={"sm"} ta={"justify"}>
                18.2 Assignment:
                <Text color='black' fw={400} fz={"sm"}>
                    You cannot assign or otherwise transfer any rights granted hereunder to any third party. The Company&rsquo;s rights under the Terms are freely transferable by the Company to its successor or any third party without the requirement of seeking Your consent.
                </Text>
            </Text>
            <Text color='black' fw={700} fz={"sm"} ta={"justify"}>
                18.3 Severability:
                <Text color='black' fw={400} fz={"sm"}>
                    If, for any reason any provision of the Terms, or any portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.
                </Text>
            </Text>
            <Text color='black' fw={700} fz={"sm"} ta={"justify"}>
                18.4 Force Majeure:
                <Text color='black' fw={400} fz={"sm"}>
                    The Company and/or the Platform shall not be liable to You for its failure to perform or for delay in providing You access to Your Account or to the Platform or any Services thereof, to the extent such failure or delay results from causes beyond its reasonable control, including, without limitation, acts of God, fires, explosions, wars or other hostilities, insurrections, revolutions, strikes, labour unrest, earthquakes, floods, pandemic, epidemics or regulatory or quarantine restrictions, unforeseeable governmental restrictions or controls or a failure by a third party hosting provider or internet service provider or on account of any change or defect in the software and/or hardware of Your computer system.
                </Text>
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                19. IP Infringement
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.1 If you believe the Platform violates Your intellectual property, You must promptly notify the Company in writing at support@spteeam.com. These notifications should only be submitted by the owner of the intellectual property, or an agent authorized to act on his/her behalf. However, any false claim by you may result in the termination of your access to the Platform.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2 You are required to provide the following details in your notice:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.1 The intellectual property that You believe is being infringed with proof of Ownership;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.2 The item that You think is infringing and include sufficient information about where the material is located on the Platform;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.3 a statement that You believe in good faith that the item You have identified as infringing is not authorized by the intellectual property owner, its agent, or the law to be used in connection with the Platform;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.4 Your contact details, such as Your address, telephone number, and/or email;
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.5 a statement, under penalty of perjury, that the information You provided in Your notice is accurate, and that You are the intellectual property owner, or an agent authorized to act on behalf of the intellectual property owner whose intellectual property is being infringed; and
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.6 Your physical or electronic signature.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                19.2.7 Please note: Company shall not consider an incomplete request.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                20. Advertisements
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                20.1. As part of the Services provided by Us; We may facilitate and allow third party advertisers (&ldquo;
                <strong>
                    ThirdParty Advertisers
                </strong>
                &rdquo;) to place advertisements on the Platform. You understand that any content put out by Third Party Advertisers is not edited, reviewed or otherwise endorsed by the Company and we disclaim to the fullest extent permitted by law any liability for the content published by the Third Party Advertisers.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                20.2. To the extent You are a Third Party Advertiser You understand that in addition to these Terms You will also be required to agree to the Company&rsquo;s policies and other contractual agreements that You will need to execute for placing Your advertisement. As a general principle the content in the advertisements should not be misleading or in violation of applicable law or guidelines issued by the Advertising Standards Council of India or any other self-regulating body. You also acknowledge that we have the sole right at our discretion to remove any Third Party Advertisement or require You to prove factual substantiation if we are of the view that it is in violation of applicable law or any self-regulating industry body guidelines or is otherwise misleading.
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                20.3. If you are of the view that the content of a third party advertiser is inappropriate or in violation of applicable law, please write to us at the email address provided above.
            </Text>
            <Text color='black' fw={700} fz={"md"} mt={15} ta={"justify"}>
                21. Modifications and Revisions:
            </Text>
            <Text color='black' fw={400} fz={"sm"} ta={"justify"}>
                The Company has the sole and final discretion to modify, alter, revise and revoke the Terms of Use &amp; Privacy Policy. You should periodically review the updated version of the Terms of Use &amp; Privacy Policy and you are bound by the above said modifications, alterations, revocations and revisions made by the Company.
            </Text>
            <Text color='black' fw={400} fz={"sm"} mt={15}>
                &copy; 2024 Quzone, All rights reserved.
            </Text>

        </div>
    )
}

export default TermsOfUse