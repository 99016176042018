import axios from 'axios';
import { httpClient } from '../lib/axios';

const pincode = localStorage.getItem('city_pincode');
const cityId = localStorage.getItem('city_id');

export const api = {
  register: async (payload) => {
    return await httpClient.post('user/', payload);
  },
  get_cities: async () => {
    return await httpClient.get('city/');
  },
  get_categories: async () => {
    return await httpClient.get('category/');
  },
  get_products: async (
    page_number,
    page_size,
    search,
    category = '',
    sub_category = null,
    order,
  ) => {
    return await httpClient.get(
      `/product/?pincode=${pincode}&page_size=${page_size}&page_number=${page_number}&search=${search}&category_id=${category}&${sub_category ? 'sub_category_id=' + sub_category : ''
      }${order ? 'order_by=' + order : ''}`,
    );
  },
  get_product: async (id) => {
    return await httpClient.get(`product/?product_id=${id}`);
  },
  change_password: async (payload) => {
    return httpClient.put(`/user/?change_password=true`, payload);
  },
  add_to_cart: async (payload) => {
    return await httpClient.post(`cart/?add_item=true&city_id=${cityId}`, payload);
  },
  get_cart: async (payload) => {
    return await httpClient.get(`cart/?city_id=${cityId}`, payload);
  },
  update_cart: async (payload) => {
    return await httpClient.post(`cart/?edit_item=true&city_id=${cityId}`, payload);
  },
  remove_cart_item: async (payload) => {
    return await httpClient.post(`cart/?remove_item=true&city_id=${cityId}`, payload);
  },
  get_addresses: async (payload) => {
    return await httpClient.get(`address/`, payload);
  },
  add_address: async (payload) => {
    return await httpClient.post(`address/`, payload);
  },
  edit_address: async (payload) => {
    return await httpClient.put(`address/`, payload);
  },
  delete_address: async (id) => {
    return await httpClient.delete(`/address/?id=${id}`);
  },
  edit_user: async (payload) => {
    return await httpClient.put(`/user/`, payload);
  },
  generate_order: async (payload) => {
    return await httpClient.post(`/get_order_id/`, payload);
  },
  verify_payment: async (payload) => {
    return await httpClient.post(`/get_verify_payment/`, payload);
  },
  create_order: async (payload) => {
    return await httpClient.post(`/order/`, payload);
  },
  get_orders: async (payload) => {
    return await httpClient.get(`/order/`);
  },
  get_order_details: async (id) => {
    return await httpClient.get(`/order/?order_id=${id}`);
  },
  get_home_products: async (payload) => {
    return await httpClient.get(
      `/product/?pincode=${pincode}&filter_type=${payload.filter}`,
    );
  },
  get_banners: async () => {
    return await httpClient.get(`/banner/`);
  },
  get_feedbacks: async () => {
    return await httpClient.get(`/feedback/?user=true`);
  },
  create_feedback: async (payload) => {
    return await httpClient.post(`/feedback/`, payload);
  },
  delete_feedback: async (id) => {
    return await httpClient.delete(`/feedback/?feedback_id=${id}`);
  },
  delete_user_data: async () => {
    return await httpClient
      .patch('user/')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  delete_user: async () => {
    return await httpClient
      .delete('user/')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  }
};

export const get_details_from_pincode = {
  get_address_details: async (payload) => {
    return axios.get(`https://api.postalpincode.in/pincode/${payload}`);
  },
};
