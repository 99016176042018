export const API_URL = process.env.REACT_APP_API;
export const image_url = process.env.REACT_APP_IMAGE;
export const is_mobile_view = process.env.REACT_APP_MOBILE === 'true';
export const theme_colors = process.env.REACT_APP_THEME_COLORS.split(",");
export const theme_font = process.env.REACT_APP_THEME_FONT;
export const theme_font_sizes = process.env.REACT_APP_THEME_FONT_SIZES;
export const contact_address = process.env.REACT_APP_CONTACT_ADDRESS;
export const contact_email = process.env.REACT_APP_CONTACT_EMAIL;
export const contact_phone = process.env.REACT_APP_CONTACT_PHONE;
export const logo_name = process.env.REACT_APP_LOGO_PATH;
export const link_facebook = process.env.REACT_APP_LINK_FACEBOOK;
export const link_instagram = process.env.REACT_APP_LINK_INSTAGRAM;
export const link_twitter = process.env.REACT_APP_LINK_TWITTER;

export const header_bg_shade = parseInt(process.env.REACT_APP_HEADER_BG_SHADE)
export const header_text_shade = parseInt(process.env.REACT_APP_HEADER_TEXT_SHADE)
export const footer_bg_shade = parseInt(process.env.REACT_APP_FOOTER_BG_SHADE)
export const primary_shade = parseInt(process.env.REACT_APP_PRIMARY_SHADE)
