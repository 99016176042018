import { Box, Container } from '@mantine/core';
import { Outlet } from 'react-router';
import AppHeader from './components/AppHeader';
import Home from './pages/Home/Home';
import AppFooter from './components/AppFooter';
import StickyFooter from './components/StickyFooter';
import { useMediaQuery } from '@mantine/hooks';
import ScrollToTop from './components/ScrollToTop';
import { useEffect, createContext, useState } from 'react';
import { App as CapacitorApp } from '@capacitor/app';
import { useNavigate, useLocation } from 'react-router-dom';
import { is_mobile_view } from './config';

export const CartContext = createContext(undefined);

function App() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const [cartItemCount, setCartItemCount] = useState(0);
  const [search, setSearch] = useState('');
  console.log(cartItemCount);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.pathname);

    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      console.log('Back button pressed', canGoBack, location.pathname);
      // if (location.pathname == '/') {
      //   CapacitorApp.exitApp();
      // } else {
      //   console.log("hey");
      //   window.history.back();
      // }
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        navigate(-1);
      }
    });
  }, []);
  return (
    <CartContext.Provider value={{ cartItemCount, setCartItemCount, search, setSearch }}>
      <div className="App">
        <ScrollToTop />
        <AppHeader />
        <Outlet />
        <AppFooter />
        {isMobileScreen ? (
          <Box
            style={{
              position: 'fixed',
              bottom: '0',
              width: '100%',
              zIndex: 10,
            }}
          >
            <StickyFooter />
          </Box>
        ) : (
          <></>
        )}
      </div>
    </CartContext.Provider>
  );
}

export default App;
