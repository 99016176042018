import { Box, Flex, List, Modal, Text, TextInput } from '@mantine/core';
import { IconLocation, IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { api } from '../services/master.service';

function CitySelectionModal({ opened, onClose }) {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    api.get_cities().then((res) => {
      let data = res.data.data;
      let flat = data.flatMap((e) => e.city);
      setCities(flat);
    });
  }, []);

  return (
    <div>
      <Modal
        opened={opened}
        withCloseButton={false}
        //onClose={onClose}
        styles={{ header: { borderBottom: '1px solid #eee' }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            <Flex align={'center'}>
              <IconLocation size={16} />
              <Text ml={14} size={'16px'}>
                Your location near to
              </Text>
            </Flex>
          </Box>
        }
        size={'400px'}
        centered
      >
        <Box p={20}>
          <TextInput placeholder="Search Area" icon={<IconSearch size={15} />} />
          <Box mt={20}>
            <List
              style={{
                padding: 0,
                listStyle: ' none',
              }}
              styles={{
                item: {
                  transition: 'all .1s ease',
                  '&:hover': {
                    background: '#eee',
                    cursor: 'pointer',
                  },
                },
              }}
            >
              {cities.map((e) => (
                <List.Item
                  key={e.id}
                  style={{
                    border: '1px solid #eee',
                    borderRadius: 10,
                    padding: '9px 18px',
                    fontSize: 13,
                    fontWeight: 500,
                    marginBottom: 13,
                  }}
                  onClick={() => {
                    localStorage.setItem('city_pincode', e.pincode);
                    localStorage.setItem('city_id', e.id);
                    localStorage.setItem('city_name', e.city);
                    window.location.reload();
                  }}
                >
                  {e.city}
                </List.Item>
              ))}
            </List>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default CitySelectionModal;
