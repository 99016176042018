import React, { useEffect } from 'react';
import { Form, Input } from 'antd';
import { api_verify_password } from '../../services/auth.service';
import { createAlert } from '../../components/Notification';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Flex } from '@mantine/core';
import { Tick } from '../../components/Tick';
import { useMediaQuery } from '@mantine/hooks';
import { is_mobile_view } from '../../config';

function EmailVerified() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  let navigate = useNavigate();
  const [queryParams, setqueryParams] = useSearchParams();

  const verifyEmail = (payload) => {
    api_verify_password(payload).then((res) => {
      console.log(res);
      if (res.data.success) {
        createAlert('success', res.data.message);
      } else {
        if (res.data.message !== 'Url already verified,Please login')
          createAlert('error', res.data.message);
      }
    });
  };

  useEffect(() => {
    console.log(queryParams.get('email'));
    console.log(queryParams.get('code'));

    verifyEmail({
      email: queryParams.get('email'),
      otp: queryParams.get('code'),
    });
  }, []);

  return (
    <>
      <Flex
        align={'center'}
        justify={'center'}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <section className="">
          <div className="px-6 text-gray-800">
            <div className="container">
              <div className="mt-20">
                <div className="block text-center">
                  <Flex align={'center'} justify={'center'} direction={'column'}>
                    <h1 className="text-xl font-bold mb-8 block text-center">Quzone</h1>
                    <Tick size={50} style={{ display: 'inline-block' }} />
                    <h1 className="text-4xl font-extrabold mb-8 text-center">
                      Your Account has been successfully verified.
                    </h1>

                    <Button
                      onClick={() => {
                        navigate('/login');
                      }}
                      className="w-100 mb-3 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    >
                      Login Now !
                    </Button>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Flex>
    </>
  );
}

export default EmailVerified;
