import {
  ActionIcon,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
} from '@tabler/icons-react';
import { Link, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mantine/hooks';
import { contact_address, contact_email, contact_phone, is_mobile_view, logo_name } from '../../config/index';

const AboutUs = () => {
  const [logo, setLogo] = useState('');

  useEffect(() => {
    import(`../../static/images/${logo_name}`).then((module) => {
      setLogo(module.default);
    });
  }, [])
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  return (
    <>
      <footer
        style={{
          //background: 'white',
          marginTop: '30px',
        }}
      >
        <Container size={'xl'}>
          <Box>
            <Grid gutter={'xl'}>
              <Grid.Col span={12} md={3} lg={3}>
                <img
                  src={logo}
                  width={'60%'}
                  height={57}
                  alt=""
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Grid.Col>
              <Grid.Col span={6} md={3} lg={3}>
                <Box
                  style={{
                    borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                  }}
                  pb={10}
                >
                  <Text color="#565656" fw={600} size={14}>
                    Contact Info
                  </Text>
                </Box>
                <Box mt={20}>
                  <Text color="#565656" size={12} fw={700} opacity={0.5}>
                    Address
                  </Text>
                  <Text size={13} color="#565656" mt={5}>
                    {contact_address}
                  </Text>
                </Box>
                <Box mt={20}>
                  <Text color="#565656" size={12} fw={700} opacity={0.5}>
                    Email Address
                  </Text>
                  <Text size={13} color="#565656" mt={5}>
                    {contact_email}
                  </Text>
                </Box>
                <Box mt={20}>
                  <Text color="#565656" size={12} fw={700} opacity={0.5}>
                    Phone Number
                  </Text>
                  <Text size={13} color="#565656" mt={5}>
                    {contact_phone}
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6} md={3} lg={3}>
                <Box
                  style={{
                    borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                  }}
                  pb={10}
                >
                  <Text color="#565656" fw={600} size={14}>
                    Important Links
                  </Text>
                </Box>
                <Box mt={20}>
                  <Text style={{ cursor: "pointer" }} onClick={() => { navigate('/privacy') }} size={13} color="#565656" mt={5}>
                    Privacy Policy
                  </Text>
                  <Text style={{ cursor: "pointer" }} onClick={() => { navigate('/terms') }} size={13} color="#565656" mt={5}>
                    Terms of Service
                  </Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={6} md={3} lg={3}>
                <Box
                  style={{
                    borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                  }}
                  pb={10}
                >
                  <Text color="#565656" fw={600} size={14}>
                    Follow us
                  </Text>
                </Box>
                <Box mt={isMobileScreen ? 10 : 20}>
                  <Flex w={'100%'}>
                    <ActionIcon mr={15}>
                      <IconBrandFacebook color="#565656" size={30} />
                    </ActionIcon>
                    <ActionIcon mr={15}>
                      <IconBrandInstagram color="#565656" size={30} />
                    </ActionIcon>
                    <ActionIcon mr={15}>
                      <IconBrandTwitter color="#565656" size={30} />
                    </ActionIcon>
                  </Flex>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Container>
      </footer>
      <Box>
        <Container size={'xl'} py={20}>
          <Text color="#565656" size={14}>
            © Quzone 2024. All rights reserved.
          </Text>
        </Container>
      </Box>
    </>
  );
};

export default AboutUs;
