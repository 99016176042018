import {
  Box,
  Divider,
  Flex,
  Grid,
  List,
  Modal,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { IconBox, IconLocation, IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { api } from '../services/master.service';
import moment from 'moment';
import { IconEye, IconEyeOff } from '@tabler/icons';

function ViewOrderModal({ opened, onClose, orderId }) {
  const [cities, setCities] = useState([]);
  const [order, setOrder] = useState({});
  const [isViewOtp, setIsViewOtp] = useState(false);
  const theme = useMantineTheme();

  useEffect(() => {
    if (orderId) {
      api.get_order_details(orderId).then((res) => {
        console.log(res.data);
        setOrder(res.data.data);
      });
    }
  }, [orderId]);

  return (
    <div>
      <Modal
        opened={opened}
        onClose={onClose}
        styles={{ header: { borderBottom: '1px solid #eee' }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            <Flex align={'center'}>
              <IconBox size={16} />
              <Text ml={14} size={'16px'} fw={600} color={theme.colors.brand[8]}>
                Order Details
              </Text>
            </Flex>
          </Box>
        }
        size={'720px'}
        centered
      >
        <Box p={20}>
          <Flex gap={'lg'} wrap={'wrap'} w={'100%'} justify={'space-between'}>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Order ID
              </Text>
              <Text size={14}> {order?.order_id} </Text>
            </Box>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Date
              </Text>
              <Text size={14}> {moment(order?.timestamp).format('DD/MM/YYYY')}</Text>
            </Box>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Status
              </Text>
              <Text size={14} tt={'capitalize'}>
                {order?.status}
              </Text>
            </Box>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Total
              </Text>
              <Text size={14}> ₹ {order?.total} </Text>
            </Box>
            <Box maw={200}>
              <Text color="dimmed" size={'xs'}>
                Address
              </Text>
              <Text size={14}> {order?.address?.address_title}</Text>
            </Box>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Payment Type
              </Text>
              <Text size={14} tt={'capitalize'}>
                {' '}
                {order?.order_type}{' '}
              </Text>
            </Box>
            <Box>
              <Text color="dimmed" size={'xs'}>
                Delivery OTP
              </Text>
              <Flex gap={'sm'}>
                <Text size={14}> {isViewOtp ? order?.user_otp : '****'} </Text>
                {isViewOtp ? (
                  <IconEye
                    onClick={() => {
                      setIsViewOtp(false);
                    }}
                    size={20}
                  />
                ) : (
                  <IconEyeOff
                    onClick={() => {
                      setIsViewOtp(true);
                    }}
                    size={16}
                  />
                )}
              </Flex>
            </Box>
          </Flex>

          <Divider my={30} />

          <Box mt={30}>
            <Text color="dimmed" size={'xs'}>
              Order Items
            </Text>
            <Box mb={20} mt={14}>
              <table width={'100%'}>
                <thead align="left">
                  <tr>
                    <th
                      style={{
                        paddingBottom: 10,
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      <Text size={14}> Product </Text>
                    </th>
                    <th
                      style={{
                        paddingBottom: 10,
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      <Text size={14}> Price </Text>
                    </th>
                    <th
                      style={{
                        paddingBottom: 10,
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      <Text size={14}> Quantity </Text>
                    </th>
                    <th
                      style={{
                        paddingBottom: 10,
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      <Text size={14}> Tax Included </Text>
                    </th>
                    <th
                      style={{
                        paddingBottom: 10,
                        borderBottom: '1px solid #eee',
                      }}
                    >
                      <Text size={14}> Sub Total </Text>
                    </th>
                  </tr>
                </thead>
                <tbody align="left">
                  {order?.cart?.cartitem.map((e) => (
                    <tr>
                      <td
                        style={{
                          padding: '16px 0px',
                        }}
                      >
                        <Text size={14}> {e.product}</Text>
                      </td>
                      <td
                        style={{
                          padding: '16px 0px',
                        }}
                      >
                        <Text size={14}> ₹ {e.selling_price} </Text>
                      </td>
                      <td
                        style={{
                          padding: '16px 0px',
                        }}
                      >
                        <Text size={14}> {e.quantity} </Text>
                      </td>
                      <td
                        style={{
                          padding: '16px 0px',
                        }}
                      >
                        <Text size={14}> ₹ {+e.tax_total.toFixed(2)} </Text>
                      </td>
                      <td
                        style={{
                          padding: '16px 0px',
                        }}
                      >
                        <Text size={14}> ₹ {+e.total.toFixed(2)} </Text>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default ViewOrderModal;
