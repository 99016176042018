import {
  HomeOutlined,
  ShoppingOutlined,
  TagsOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Box, Flex, Text } from '@mantine/core';
import React from 'react';

import { Route, Link, Routes, useLocation } from 'react-router-dom';

function StickyFooter() {
  const location = useLocation();
  return (
    <div>
      <Flex align={'center'} justify={'center'} w={'100vw'}>
        {/* <!-- <section id="bottom-navigation" className="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> // if shown only tablet/mobile--> */}
        <Box
          bg={'white'}
          pb={5}
          pt={3}
          w={'100vw'}
          h={'8vh'}
          style={{ borderTop: '2px solid rgba(0,0,0,0.2)' }}
        >
          <Flex align={'center'} justify={'center'} h={'100%'}>
            <Link
              to="/"
              style={{ textDecoration: 'none', width: '100%', padding: '5px' }}
            >
              <Flex align={'center'} justify={'center'} direction={'column'}>
                <HomeOutlined
                  size={10}
                  style={{ color: location.pathname === '/' ? 'blue' : 'grey' }}
                />
                <Text
                  fz={12}
                  fw={800}
                  color={location.pathname === '/' ? 'blue' : 'grey'}
                >
                  Home
                </Text>
              </Flex>
            </Link>
            <Link
              to="/categories"
              style={{ textDecoration: 'none', width: '100%', padding: '3px' }}
            >
              <Flex align={'center'} justify={'center'} direction={'column'}>
                <TagsOutlined
                  size={10}
                  style={{ color: location.pathname === '/categories' ? 'blue' : 'grey' }}
                />
                <Text
                  fz={12}
                  fw={800}
                  color={location.pathname === '/categories' ? 'blue' : 'grey'}
                >
                  Categories
                </Text>
              </Flex>
            </Link>

            <Link
              to="/products"
              style={{ textDecoration: 'none', width: '100%', padding: '3px' }}
            >
              <Flex align={'center'} justify={'center'} direction={'column'}>
                <ShoppingOutlined
                  size={10}
                  style={{ color: location.pathname === '/products' ? 'blue' : 'grey' }}
                />
                <Text
                  fz={12}
                  fw={800}
                  color={location.pathname === '/products' ? 'blue' : 'grey'}
                >
                  Products
                </Text>
              </Flex>
            </Link>
            <Link
              to="/account"
              style={{ textDecoration: 'none', width: '100%', padding: '3px' }}
            >
              <Flex align={'center'} justify={'center'} direction={'column'}>
                <UserOutlined
                  size={10}
                  style={{ color: location.pathname === '/account' ? 'blue' : 'grey' }}
                />
                <Text
                  fz={12}
                  fw={800}
                  color={location.pathname === '/account' ? 'blue' : 'grey'}
                >
                  Account
                </Text>
              </Flex>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </div>
  );
}

export default StickyFooter;
