import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import { api_forgot_password } from '../../services/auth.service';
import { createAlert } from '../../components/Notification';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Image, Paper, Text } from '@mantine/core';
import Shopping from '../../static/images/shopping.svg';
import { useMediaQuery } from '@mantine/hooks';
import { is_mobile_view } from '../../config';

function ForgetPassword() {
  let navigate = useNavigate();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  const forget_view = is_mobile_view && !isMobileScreen;
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const handleFormSuccess = (values) => {
    // alert(1);
    api_forgot_password({
      username: values.username,
    }).then((res) => {
      console.log(res);
      if (res.data.success) {
        createAlert('success', res.data.message);
      } else {
        createAlert('error', res.data.message);
      }
    });
  };

  const handleFormError = (params) => {};

  return (
    <>
      <Box
        p={20}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <section className="h-screen">
          <div className="px-6 text-gray-800">
            <div className="container">
              <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
                <Row className="row">
                  {isMobileScreen && !forget_view ? (
                    <></>
                  ) : (
                    <Col flex="4 0 25%">
                      <div className="grow-0 shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0 hidden sm:block">
                        {/* <img 
                      src="https://img.freepik.com/free-vector/forgot-password-concept-illustration_114360-1123.jpg?w=996&t=st=1670078263~exp=1670078863~hmac=5f08a30b45edbf4b950fc7844588b6f1658547df290878098e12adb9fc2013d2"
                      className="w-full"
                      alt="Sample"
                      width={600}
                    /> */}
                        <Flex align={'center'} justify={'center'} h={'100%'}>
                          <Image src={Shopping} alt="" width={400} />
                        </Flex>
                      </div>
                    </Col>
                  )}
                  <Col flex="1 0 25%">
                    <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                      <Text size={'xs'}> Quzone </Text>
                      <Text fw={600} size="xl">
                        Forget Password
                      </Text>
                      <Paper withBorder shadow="md" p={30} mt={20} radius="md" maw={400}>
                        <Form
                          name="basic"
                          layout="vertical"
                          initialValues={{ remember: true }}
                          onFinish={handleFormSuccess}
                          onFinishFailed={handleFormError}
                          autoComplete="off"
                        >
                          <Form.Item
                            className="hide-astricks"
                            label="Mobile number"
                            name="username"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter mobile.',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Your Registered Mobile Number" />
                          </Form.Item>

                          <Form.Item>
                            <div className="text-center lg:text-left">
                              <Button
                                type="submit"
                                className="w-100 mb-3 px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                              >
                                Submit
                              </Button>
                            </div>
                          </Form.Item>
                        </Form>
                      </Paper>

                      <div className="flex justify-between items-center mb-6">
                        <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                          Remember your password ?
                          <span
                            onClick={() => {
                              navigate('/login');
                            }}
                            className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out ml-1 cursor-pointer hover:cursor-pointer"
                          >
                            Login
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </section>
      </Box>
    </>
  );
}

export default ForgetPassword;
