import { css } from '@emotion/react';
import {
  ActionIcon,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Image,
  Paper,
  Radio,
  Rating,
  Table,
  Tabs,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconArrowRight, IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/master.service';
import { Link, useNavigate } from 'react-router-dom';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import Orders from './Orders';
import Addresses from './Addresses';
import AccountDetails from './AccountDetails';
import Feedback from './Feedback';
import AboutUs from './AboutUs';
import { is_mobile_view } from '../../config';

function AccountPage() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [cart, setCart] = useState({});

  useEffect(() => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
    });
  }, []);

  const getData = () => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      setCart(res.data.data);
    });
  };

  const updateQty = (id, qty) => {
    if (qty <= 1) {
      removeItem(id);
    } else {
      api
        .update_cart({
          item_id: id,
          quantity: qty,
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.success) {
            showSuccessToast({
              title: 'Success',
              message: 'Cart Updated.',
            });
            getData();
          } else {
            showErrorToast({
              title: 'Error',
              message: 'Error updating cart.',
            });
          }
        });
    }
  };

  const removeItem = (id) => {
    api
      .remove_cart_item({
        item_id: id,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: 'Cart Updated.',
          });
          getData();
        } else {
          showErrorToast({
            title: 'Error',
            message: 'Error updating cart.',
          });
        }
      });
  };

  const handleDeleteAccount = async () => {
    await api.delete_user().then(
      res => {
        if (res.success) {
          console.log(res);
          localStorage.clear();
          showSuccessToast({ title: "Success", message: res.message });
          navigate("/login");
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      }
    ).catch(err => {
      console.log(err);
    })
  }

  const handleDeleteData = async () => {
    await api.delete_user_data().then(
      res => {
        if (res.success) {
          console.log(res);
          showSuccessToast({ title: "Success", message: res.message });
        } else {
          showErrorToast({ title: "Error", message: res.message });
        }
      }
    ).catch(err => {
      console.log(err);
    })
  }


  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <Box mb={isMobileScreen ? 14 : 24} px={10}>
          <Breadcrumbs separator="/">
            <Text size={14} color="dimmed">
              Home
            </Text>
            <Text size={14}>Account</Text>
          </Breadcrumbs>
        </Box>

        {/* Categories */}
        <Box p={10}>
          <Flex align={'center'} justify={'space-between'} wrap={"wrap"} gap={10}>
            <Text size={'xl'} fw={600}>
              Account
            </Text>
            {localStorage.getItem('user_token') && isMobileScreen ? (
              <Button
                variant="outline"
                onClick={() => {
                  let city_name = localStorage.getItem('city_name');
                  let city_pincode = localStorage.getItem('city_pincode');
                  let city_id = localStorage.getItem('city_id');
                  localStorage.clear();
                  localStorage.setItem('city_name', city_name);
                  localStorage.setItem('city_pincode', city_pincode);
                  localStorage.setItem('city_id', city_id);
                  navigate('/');
                }}
              >
                Logout
              </Button>
            ) : (
              <></>
            )}
          </Flex>
          <Flex w={"100%"} align={"center"} justify={"right"} gap={10} wrap={"wrap"} my={10}>
            <Button color='red' variant='outline' onClick={handleDeleteAccount}>Delete My Account</Button>
            {/* <Button onClick={handleDeleteData}>Delete My Data</Button> */}
          </Flex>

          <Box mt={isMobileScreen ? 10 : 50}>
            <Tabs
              defaultValue="gallery"
              orientation={isMobileScreen ? 'horizontal' : 'vertical'}
              style={{ width: '100%' }}
            >
              <Tabs.List>
                <Flex
                  direction={isMobileScreen ? 'row' : 'column'}
                  align={'center'}
                  justify={'flex-start'}
                  style={{ width: '100%', overflowX: 'auto' }}
                >
                  <Tabs.Tab value="gallery" style={{ width: '99%', height: '100%' }}>
                    <Box pr={40} pb={20}>
                      My Orders
                    </Box>
                  </Tabs.Tab>
                  <Tabs.Tab value="messages" style={{ width: '99%', height: '100%' }}>
                    <Box pr={40} pb={20}>
                      Manage Addresses
                    </Box>
                  </Tabs.Tab>
                  <Tabs.Tab value="settings" style={{ width: '99%', height: '100%' }}>
                    <Box pr={40} pb={20}>
                      Account Details
                    </Box>
                  </Tabs.Tab>
                  <Tabs.Tab value="feedback" style={{ width: '99%', height: '100%' }}>
                    <Box pr={40} pb={20}>
                      Feedback / Suggested products
                    </Box>
                  </Tabs.Tab>
                  {isMobileScreen ? (
                    <Tabs.Tab value="about" style={{ width: '99%', height: '100%' }}>
                      <Box pr={40} pb={20}>
                        To know us
                      </Box>
                    </Tabs.Tab>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Tabs.List>

              <Tabs.Panel value="gallery" mt={20}>
                <Box px={10} pb={10} ml={isMobileScreen ? 0 : 50}>
                  <Text size={'lg'} fw={600}>
                    My Orders
                  </Text>
                  <Orders />
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="messages" mt={20}>
                <Box px={10} pb={10} ml={isMobileScreen ? 0 : 50}>
                  <Text size={'lg'} fw={600}>
                    Manage Addresses
                  </Text>
                  <Addresses />
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="settings" mt={20}>
                <Box px={10} pb={10} ml={isMobileScreen ? 0 : 50}>
                  <AccountDetails />
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="feedback">
                <Box px={10} pb={10} ml={isMobileScreen ? 0 : 50}>
                  <Feedback />
                </Box>
              </Tabs.Panel>
              {isMobileScreen ? (
                <Tabs.Panel value="about">
                  <Box px={10} pb={10} ml={isMobileScreen ? 0 : 50}>
                    <AboutUs />
                  </Box>
                </Tabs.Panel>
              ) : (
                <></>
              )}
            </Tabs>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default AccountPage;
