import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  Flex,
  Alert,
  Grid,
  Box,
  Image,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconAlertCircle } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { api_login } from '../../services/auth.service';
import { api } from '../../services/master.service';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import Shopping from '../../static/images/shopping.svg';
import { useMediaQuery } from '@mantine/hooks';
import { is_mobile_view } from '../../config';

export function Login() {
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  const login_view = is_mobile_view && !isMobileScreen;
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;

  // Starts : States
  const [loading, setLoading] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const [checked, setChecked] = useState(false);
  // ENDS : States

  const form = useForm({
    initialValues: {
      username: '',
      password: '',
    },

    validate: {
      username: (value) => (value ? null : 'Username is required'),
      password: (value) => (value ? null : 'Password is required'),
    },
  });

  const registerForm = useForm({
    initialValues: {
      mobile: '',
      password: '',
      confirm_password: '',
      first_name: '',
      email: '',
    },
    validate: {
      first_name: (value) => (value ? null : 'First name is required'),
      email: (value) => (value ? null : 'Email is required'),
      mobile: (value) => (value ? null : 'Phone No. is required'),
      password: (value) => (value ? null : 'Password is required'),
      confirm_password: (value, values) =>
        value !== values.password ? 'Passwords did not match' : null,
    },
  });

  const doLogin = async () => {
    setLoading(true);
    console.log(form.values);
    await api_login({
      ...form.values,
      role: 'customer',
    })
      .then((res) => {
        setLoading(false);
        console.log(res);
        if (res.success) {
          localStorage.setItem('user_token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          navigate('/');
        } else {
          setLoginError(true);
          setLoginErrorMessage(res.message);
        }
      })
      .catch((err) => {
        setLoginError(true);
        setLoginErrorMessage(err.message);
      });
    setLoading(false);
  };

  const doRegister = async () => {
    setLoading(true);
    console.log(registerForm.values);
    await api
      .register({
        ...registerForm.values,
        role: 'customer',
      })
      .then((res) => {
        setLoading(false);
        console.log(res.data);
        if (res.data.success) {
          navigate('/');
          showSuccessToast({ title: 'Success', message: res.data.message });
        } else {
          showErrorToast({ title: 'Error', message: res.data.message });
        }
      })
      .catch((err) => {
        showErrorToast({ title: 'Error', message: err.message });
      });
    setLoading(false);
  };

  return (
    <>
      <Box
        px={isMobileScreen ? 14 : 30}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
        w={'100%'}
      >
        <Container size={'xl'} mt={isMobileScreen ? 10 : 50} mb={100} w={'100%'}>
          <Flex align={'center'} justify={'center'} w={'100%'}>
            <Grid gutter={'lg'} w={'100%'}>
              {isMobileScreen && !login_view ? (
                <></>
              ) : (
                <Grid.Col span={8}>
                  <Flex align={'center'} justify={'center'} h={'100%'}>
                    <Image src={Shopping} alt="" width={400} />
                  </Flex>
                </Grid.Col>
              )}
              {!isRegister ? (
                <Grid.Col span={12} md={4} lg={4}>
                  <form onSubmit={form.onSubmit(doLogin)}>
                    <Text size={'xs'}> Welcome, </Text>
                    <Text fw={600} size="xl">
                      Login to Quzone.
                    </Text>

                    {loginError ? (
                      <Alert
                        mt={20}
                        icon={<IconAlertCircle size={16} />}
                        title="Error!"
                        color="red"
                      >
                        {loginErrorMessage}
                      </Alert>
                    ) : (
                      ''
                    )}




                    <Paper withBorder shadow="md" p={30} mt={20} radius="md">
                      <TextInput
                        label="Phone Number (Username)"
                        placeholder="Enter Your Phone No."
                        {...form.getInputProps('username')}
                      />
                      <PasswordInput
                        label="Password"
                        placeholder="Enter Your password"
                        mt="md"
                        {...form.getInputProps('password')}
                      />
                      <Group position="apart" mt="lg">
                        <Anchor
                          onClick={(event) => {
                            event.preventDefault();
                            navigate('/forget');
                          }}
                          href="#"
                          size="sm"
                        >
                          Forgot password?
                        </Anchor>
                      </Group>
                      <Button loading={loading} type="submit" fullWidth mt="xl">
                        Sign in
                      </Button>
                    </Paper>
                  </form>

                  <Text fz={'md'} fw={500} mt={10}>
                    Don't Have an account ?{' '}
                    <a
                      style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                      color={'green'}
                      onClick={() => setIsRegister(true)}
                    >
                      Register
                    </a>
                  </Text>
                </Grid.Col>
              ) : (
                <Grid.Col span={12} md={4} lg={4}>
                  <form
                    onSubmit={registerForm.onSubmit((values) => {
                      if (checked) {
                        doRegister();
                      }
                    })}
                    onError={(err) => {
                      console.log(err);
                    }}
                  >














                    <Text size={'xs'}> New to quzone ? </Text>
                    <Text fw={600} size="xl">
                      Register Now.
                    </Text>

                    <Paper withBorder shadow="md" p={30} mt={20} radius="md">
                      <TextInput
                        label="First Name"
                        placeholder="Enter Your first name"
                        {...registerForm.getInputProps('first_name')}
                      />
                      <TextInput
                        label="Email Address"
                        placeholder="Enter Your email address"
                        mt="md"
                        {...registerForm.getInputProps('email')}
                      />
                      <TextInput
                        type="number"
                        label="Phone Number ( Username )"
                        placeholder="Enter Your phone no."
                        mt="md"
                        {...registerForm.getInputProps('mobile')}
                      />
                      <PasswordInput
                        label="Password"
                        placeholder="Enter Your password"
                        mt="md"
                        {...registerForm.getInputProps('password')}
                      />
                      <PasswordInput
                        label="Confirm Password"
                        placeholder="Enter confirm password"
                        mt="md"
                        {...registerForm.getInputProps('confirm_password')}
                      />
                      <Checkbox my={20}
                        label={
                          <>
                            I accept{' '}
                            <Anchor fw={700} href="/terms" target="_blank" inherit>
                              Terms of service
                            </Anchor>
                          </>
                        }
                        checked={checked}
                        onChange={(event) => setChecked(event.currentTarget.checked)}
                      />

                      <Button disabled={!checked} loading={loading} type="submit" fullWidth mt="xl">
                        Register
                      </Button>
                    </Paper>
                  </form>

                  <Text fz={'md'} fw={500} mt={10}>
                    Already Have an account ?{' '}
                    <a
                      style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                      color={'green'}
                      onClick={() => setIsRegister(false)}
                    >
                      Login
                    </a>
                  </Text>
                </Grid.Col>
              )}
            </Grid>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
