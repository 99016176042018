import {
  ActionIcon,
  Box,
  Breadcrumbs,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Modal,
  Paper,
  Rating,
  Table,
  Tabs,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconAlignJustified,
  IconCash,
  IconChevronDown,
  IconHeart,
  IconList,
  IconLock,
  IconMinus,
  IconPlus,
  IconShare,
  IconShoppingCartPlus,
  IconStars,
  IconTruckDelivery,
} from '@tabler/icons-react';
import React, { useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { api } from '../../services/master.service';
import { image_url, is_mobile_view } from '../../config';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import { Input, Rate } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import { order, review } from '../../services/website.service';
import { createAlert } from '../../components/Notification';
import { CartContext } from '../../App';

function ProductView() {
  const navigate = useNavigate();

  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const { cartItemCount, setCartItemCount } = useContext(CartContext);

  const [product, setProduct] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [specifications, setSpecifications] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stars, setStars] = useState(2.5);
  const [reviewTitle, setReviewTitle] = useState();
  const [reviewText, setReviewText] = useState();
  const [loading, setLoading] = useState();
  const [modalDetails, setModalDetails] = useState();
  const [orders, setOrders] = useState([]);
  const [ordersList, setOrdersList] = useState();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
    api.get_product(searchParams.get('id')).then((res) => {
      console.log(res.data);
      setProduct(res.data.data);
      setSpecifications(JSON.parse(res.data.data.specification));
      if (res.data.data.product_image.length > 0) {
        setImages(
          res.data.data.product_image.map((e) => {
            return {
              id: e.id,
              image: image_url + e.image,
            };
          }),
        );
        setSelectedImage(image_url + res.data.data.product_image[0].image);
      } else {
        setImages([
          {
            id: 0,
            image:
              'https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/01/placeholder-277.png',
          },
        ]);
        setSelectedImage(
          'https://d23qowwaqkh3fj.cloudfront.net/wp-content/uploads/2022/01/placeholder-277.png',
        );
      }
    });
  }, [searchParams]);

  const addToCart = async () => {
    await api
      .add_to_cart({
        product_id: product?.id,
        quantity: quantity,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.success) {
          setCartItemCount(res.data.data?.cartitem.length);
          showSuccessToast({
            title: 'Success',
            message: 'Item successfully added to cart.',
          });
        } else {
          if (res.data.code === 401) {
            navigate('/login');
            showErrorToast({
              title: 'Warning',
              message: 'You have to login to add items to cart.',
            });
          } else {
            showErrorToast({
              title: 'Error',
              message: res.data.message,
            });
          }
        }
      });
  };

  const submitReview = (e) => {
    if (reviewText && reviewTitle) {
      review
        .add({
          //order_id: modalDetails.order_id,
          product_id: searchParams.get('id'),
          title: reviewTitle,
          description: reviewText,
          star: stars,
        })
        .then((res) => {
          console.log(res);
          if (res.success) {
            createAlert('success', 'Review added successfully.');
            // setShowReview(false);
            setIsModalVisible(false);
            orders.get().then((res) => {
              setLoading(false);
              console.log(res);
              if (res.success) {
                setOrdersList(res.data);
              }
            });
          } else {
            createAlert('error', res.message);
          }
        });
    } else {
      createAlert('error', 'Please enter review details');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
        mb={100}
      >
        <Box mb={24} px={10}>
          <Breadcrumbs separator="/">
            <Link
              size={14}
              to={'/'}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
              }}
            >
              Home
            </Link>
            <Link
              size={14}
              to={'/products'}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
              }}
            >
              Products
            </Link>
            <Text size={14} color="dimmed">
              Amul Butter 500 Grams
            </Text>
          </Breadcrumbs>
        </Box>
        <Paper pos={'relative'} shadow="sm" bg={'white'} p={isMobileScreen ? 30 : 30}>
          <Grid>
            <Grid.Col sm={6}>
              <Grid>
                <Grid.Col hidden={isMobileScreen} sm={2} order={isMobileScreen ? 1 : 0}>
                  <Flex
                    sx={{ overflowX: 'scroll' }}
                    direction={isMobileScreen ? 'row' : 'column'}
                    justify={'space-between'}
                    w={'100%'}
                    ml={isMobileScreen ? 0 : 10}
                  >
                    {images?.map((e) => (
                      <Image
                        key={e.id}
                        sx={{
                          border: '1px solid #ccc',
                          borderRadius: 2,
                          overflow: 'hidden',
                        }}
                        mb={10}
                        width={'60px'}
                        height={'60px'}
                        src={e.image}
                        onClick={() => {
                          setSelectedImage(e.image);
                        }}
                      ></Image>
                    ))}
                  </Flex>
                </Grid.Col>
                <Grid.Col sm={isMobileScreen ? 12 : 10}>
                  <img
                    alt=""
                    src={selectedImage}
                    style={{
                      height: '450px',
                      width: '100%',
                      objectFit: 'contain',
                    }}
                  ></img>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col sm={6}>
              <Box pl={isMobileScreen ? 0 : 20}>
                <Flex></Flex>
                <Text fw={'600'} size={'24px'}>
                  {product?.title}
                </Text>
                <Box mb={20}>
                  <Flex align={'center'}>
                    <Rating
                      styles={{
                        root: {
                          marginBottom: '0px !important',
                        },
                      }}
                      readOnly
                      mb={15}
                      defaultValue={product?.review}
                      value={product?.review}
                      size="xs"
                    />
                    <Text ml={5} size={'11px'}>
                      ( {product?.review} )
                    </Text>
                  </Flex>
                </Box>
                <Box hidden={isMobileScreen}>
                  <Text size={'14px'} color="dimmed" mb={40}>
                    {product?.short_description}
                  </Text>
                </Box>
                <Flex align={'center'} justify={'space-between'} my={15}>
                  <Flex align={'center'}>
                    <Text strikethrough size={16} mr={10} color="gray" opacity={0.7}>
                      ₹ {product?.mrp}
                    </Text>
                    <Text fw={500} size={20}>
                      ₹ {product?.selling_price}
                    </Text>
                  </Flex>
                  <Flex>
                    <ActionIcon
                      bg={'white'}
                      color="gray"
                      variant="outline"
                      radius="xl"
                      size="lg"
                    >
                      <IconShare style={{ width: 18 }} />
                    </ActionIcon>
                    {/* <ActionIcon
                      bg={'white'}
                      color="gray"
                      variant="outline"
                      radius="xl"
                      size="lg"
                      ml={10}
                    >
                      <IconHeart style={{ width: 18 }} />
                    </ActionIcon> */}
                  </Flex>
                </Flex>
                <Box
                  mt={30}
                  sx={
                    isMobileScreen
                      ? {
                          borderTop: '2px solid #eee',
                          position: 'fixed',
                          bottom: '8vh',
                          left: 0,
                          width: '100vw',
                          backgroundColor: 'white',
                          padding: '10px 20px',
                          // paddingTop: '10px',
                          zIndex: 5,
                        }
                      : {}
                  }
                >
                  <Text size={'14px'}>Quantity</Text>
                  <Flex align={'center'}>
                    <Flex
                      w={isMobileScreen ? '200px' : 'auto'}
                      justify={isMobileScreen && 'space-between'}
                      align={'center'}
                      sx={{ borderRadius: 5 }}
                      py={9}
                    >
                      <ActionIcon
                        p={4}
                        bg={theme.colors.gray[1]}
                        size={'lg'}
                        variant="light"
                        onClick={() => {
                          quantity > 2 ? setQuantity(quantity - 1) : setQuantity(1);
                        }}
                      >
                        <IconMinus />
                      </ActionIcon>
                      <Text fw={600} mx={20}>
                        {quantity}
                      </Text>
                      <ActionIcon
                        p={4}
                        bg={theme.colors.gray[1]}
                        size={'lg'}
                        variant="light"
                        onClick={() => {
                          quantity >= 1 ? setQuantity(quantity + 1) : setQuantity(1);
                        }}
                      >
                        <IconPlus />
                      </ActionIcon>
                    </Flex>
                    <Button
                      ml={isMobileScreen ? 15 : 10}
                      w={'100%'}
                      size="md"
                      leftIcon={<IconShoppingCartPlus size={20} />}
                      onClick={addToCart}
                    >
                      Add to Cart
                    </Button>
                  </Flex>
                </Box>

                <Box mt={20}>
                  <Grid>
                    {/* <Grid.Col sm="6">
                      <Flex align={'center'}>
                        <IconTruckDelivery size={18} />
                        <Text ml={10} size={13}>
                          Delivery
                        </Text>
                      </Flex>
                      <Text size={'12px'} color="dimmed">
                        5-7 Working Days
                      </Text>
                    </Grid.Col> */}
                    <Grid.Col sm="6">
                      <Flex align={'center'}>
                        <IconCash size={18} />
                        <Text ml={10} size={13}>
                          Cash On Delivery
                        </Text>
                      </Flex>
                      <Text size={'12px'} color="dimmed">
                        {product?.cod ? 'Available' : 'Not Available'}
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Box>

                <Box bg={theme.colors.gray[0]} p={isMobileScreen ? 17 : 20} mt={25}>
                  <Flex mb={10} align={'center'} justify={'center'}>
                    <IconLock size={15} />
                    <Text ml={5} fw={600} size={14}>
                      Guranteed Safe & Secure Checkout
                    </Text>
                  </Flex>
                  <Image
                    mx={'auto'}
                    width={200}
                    src={
                      'https://minimog-4437.kxcdn.com/megastore/wp-content/themes/minimog/assets/woocommerce/product-trust-badge.png'
                    }
                  />
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </Paper>

        {/* Description & Reviews */}
        <Paper shadow="sm" bg={'white'} p={30} my={30}>
          <Box>
            <Tabs defaultValue="gallery">
              <Tabs.List grow>
                <Tabs.Tab value="gallery" icon={<IconAlignJustified size="16px" />}>
                  <Text fw={600} size={18}>
                    Description
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab value="reviews" icon={<IconStars size="16px" />}>
                  <Text fw={600} size={18}>
                    Reviews ( {product?.product_review.length} )
                  </Text>
                </Tabs.Tab>
                <Tabs.Tab value="specifications" icon={<IconList size="16px" />}>
                  <Text fw={600} size={18}>
                    Specifications
                  </Text>
                </Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="gallery" pt="xs">
                <Text my={20} lh={1.8} size={15}>
                  {product?.long_description}
                </Text>
              </Tabs.Panel>

              <Tabs.Panel value="reviews" pt="40px">
                <Box p={10}>
                  <Flex align={'center'} justify={'right'}>
                    <Button
                      onClick={() => {
                        setIsModalVisible(true);
                      }}
                    >
                      Write a Review
                    </Button>
                  </Flex>
                  <Flex mb={15}>
                    {/* <Image
                      radius={200}
                      width={'50px'}
                      height={'50px'}
                      fit={'contain'}
                      src="https://picsum.photos/500"
                    ></Image> */}
                    {product?.product_review.map((e, i) => (
                      <Box ml={15} key={i}>
                        <Rating
                          defaultValue={5}
                          mb={5}
                          value={e.star}
                          readOnly
                          size="xs"
                        />

                        <Text size={'14px'} fw={600}>
                          {e.title}
                        </Text>
                        <Text size={'13px'}>{e.description}</Text>

                        <Text size={'12px'} color="dimmed" mt={5}>
                          {e.user}
                        </Text>
                      </Box>
                    ))}
                  </Flex>
                </Box>

                {/* <Flex justify={'center'} align={'center'}>
                  <Button variant="subtle" leftIcon={<IconChevronDown />}>
                    Load More Reviews ..
                  </Button>
                </Flex> */}
              </Tabs.Panel>

              <Tabs.Panel value="specifications" pt="xs">
                <Box my={20}>
                  <Table withBorder withColumnBorders>
                    <tbody>
                      {specifications.length !== 0 ? (
                        specifications.map((e, i) => (
                          <tr>
                            <td>
                              <strong> {e.title} </strong>
                            </td>
                            <td> {e.description} </td>
                          </tr>
                        ))
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </Table>
                </Box>
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Paper>
      </Container>

      <Modal
        size="55%"
        opened={isModalVisible}
        footer={false}
        onClose={handleCancel}
        title="Product Review"
      >
        <>
          <div className="review border border-slate-300 mx-2 mt-8 p-3 rounded">
            <h5> Give your review about product. </h5>
            <Rate
              allowHalf
              defaultValue={stars}
              onChange={(e) => {
                console.log(e);
                setStars(e);
              }}
            />
            <Box mt={10}>
              <Box mb={10}>
                <Input
                  mb={3}
                  placeholder="Enter review title."
                  onChange={(e) => {
                    setReviewTitle(e.target.value);
                  }}
                />
              </Box>
              <div>
                <Textarea
                  autosize
                  minRows={4}
                  placeholder="Enter your review."
                  onChange={(e) => {
                    setReviewText(e.target.value);
                  }}
                ></Textarea>
              </div>
            </Box>
            <div className="mt-5">
              <Button mt={10} onClick={submitReview}>
                Submit your Review
              </Button>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default ProductView;
