import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Global styles
import './styles/global.scss';
import { Login } from './pages/Auth/Login';
import { NotFound } from './pages/Errors/NotFound';
import { ProtectedRoutes } from './router/ProtectedRoute';

// React query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import ProductView from './pages/Product/ProductView';
import Home from './pages/Home/Home';
import CategoriesPage from './pages/Categories';
import CategoryPage from './pages/CategoryPage';
import AllProducts from './pages/AllProducts';
import CartPage from './pages/Cart';
import CheckoutPage from './pages/Checkout';
import AccountPage from './pages/Account/Account';
import ForgetPassword from './pages/Auth/ForgetPassword';
import EmailVerified from './pages/Auth/email-verified';
import { primary_shade, theme_colors, theme_font, theme_font_sizes } from './config';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse';

const queryClient = new QueryClient({
  // staleTime: Infinity,
  refetchOnWindowFocus: 'always',
  refetchOnReconnect: false,
  retry: false,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    {/* <ReactQueryDevtools /> */}
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colors: {
          brand: theme_colors,
        },
        globalStyles: (theme) => ({
          body: {
            color: theme.colors.brand[8],
            background: '#e0e5eb33',
          },
        }),
        primaryShade: primary_shade,
        primaryColor: 'brand',
        fontFamily: theme_font,
        fontSizes: JSON.parse(theme_font_sizes),
      }}
    >
      <ModalsProvider>
        <NotificationsProvider position="bottom-right">
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />}>
                <Route path="product" element={<ProductView />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="terms" element={<TermsOfUse />} />
                <Route path="categories" element={<CategoriesPage />} />
                <Route path="category" element={<CategoryPage />} />
                <Route path="products" element={<AllProducts />} />
                <Route path="cart" element={<CartPage />} />
                <Route path="checkout" element={<CheckoutPage />} />
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="forget" element={<ForgetPassword />} />
                <Route path="verification" element={<EmailVerified />} />
                <Route element={<ProtectedRoutes />}>
                  <Route path="account" element={<AccountPage />} />
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  </QueryClientProvider>,
);
