import {
  ActionIcon,
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  Text,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { api } from '../services/master.service';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandTwitter,
} from '@tabler/icons-react';
import { IconX } from '@tabler/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useMediaQuery } from '@mantine/hooks';
import { contact_address, contact_email, contact_phone, footer_bg_shade, is_mobile_view, link_facebook, link_instagram, link_twitter, logo_name } from '../config';

function AppFooter() {
  const [logo, setLogo] = useState('');
  useEffect(() => {
    import(`../static/images/${logo_name}`).then((module) => {
      setLogo(module.default);
    });
  }, [])
  const navigate = useNavigate();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const location = useLocation();

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    api.get_categories().then((res) => {
      console.log(res.data.data);
      let filtered_categories = res.data.data.filter((e) => e.sub_category.length > 1);
      setCategories(filtered_categories);
    });
  }, []);

  return (
    <div
      style={{
        background: '#eee',
        marginTop: 50,
      }}
    >
      {!isMobileScreen ? (
        <Container size={'xl'}>
          <Box p={30}>
            <Text size={14}>Our Range of products</Text>
            <Divider my={20} />
            <Grid>
              {categories.map((e) => (
                <Grid.Col span={6} md={3} lg={3} key={e.id}>
                  <Link
                    to={`/category/?id=${e.id}`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    color="inherit"
                    fw={600}
                    size={14}
                    mb={5}
                    tt={'capitalize'}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 5,
                      display: 'block',
                    }}
                  >
                    {e.title}
                  </Link>
                  <ul
                    style={{
                      margin: 0,
                      padding: 0,
                      fontSize: 14,
                      marginBottom: 30,
                      listStyleType: 'none',
                    }}
                  >
                    {e.sub_category.map((x) => (
                      <li
                        key={e.id}
                        style={{
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          navigate(`/category/?id=${e.id}&subcategory=${x.id}`);
                          window.scrollTo(0, 0);
                        }}
                        onMouseOver={(e) => {
                          e.target.style.textDecoration = 'underline';
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.textDecoration = 'none';
                        }}
                      >
                        {x.title}
                      </li>
                    ))}
                  </ul>
                </Grid.Col>
              ))}
            </Grid>
          </Box>
        </Container>
      ) : (
        <></>
      )}
      {isMobileScreen ? (
        <></>
      ) : (
        <>
          <footer
            style={{
              background: theme.colors.brand[footer_bg_shade],
            }}
          >
            <Container size={'xl'} py={30}>
              <Box p={20}>
                <Grid gutter={'xl'}>
                  <Grid.Col span={12} md={3} lg={3}>
                    <img
                      src={logo}
                      width={'60%'}
                      height={57}
                      alt=""
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </Grid.Col>
                  <Grid.Col span={6} md={3} lg={3}>
                    <Box
                      style={{
                        borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                      }}
                      pb={10}
                    >
                      <Text color="#565656" fw={600} size={14}>
                        Contact Info
                      </Text>
                    </Box>
                    <Box mt={20}>
                      <Text color="#565656" size={12} fw={700} opacity={0.5}>
                        Address
                      </Text>
                      <Text size={13} color="#565656" mt={5}>
                        {contact_address}
                      </Text>
                    </Box>
                    <Box mt={20}>
                      <Text color="#565656" size={12} fw={700} opacity={0.5}>
                        Email Address
                      </Text>
                      <Text size={13} color="#565656" mt={5}>
                        {contact_email}
                      </Text>
                    </Box>
                    <Box mt={20}>
                      <Text color="#565656" size={12} fw={700} opacity={0.5}>
                        Phone Number
                      </Text>
                      <Text size={13} color="#565656" mt={5}>
                        {contact_phone}
                      </Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6} md={3} lg={3}>
                    <Box
                      style={{
                        borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                      }}
                      pb={10}
                    >
                      <Text color="#565656" fw={600} size={14}>
                        Important Links
                      </Text>
                    </Box>
                    <Box mt={20}>
                      <Text style={{ cursor: "pointer" }} onClick={() => { navigate('/privacy') }} size={13} color="#565656" mt={5}>
                        Privacy Policy
                      </Text>
                      <Text style={{ cursor: "pointer" }} onClick={() => { navigate('/terms') }} size={13} color="#565656" mt={5}>
                        Terms of Service
                      </Text>
                    </Box>
                  </Grid.Col>
                  <Grid.Col span={6} md={3} lg={3}>
                    <Box
                      style={{
                        borderBottom: '1px solid rgba(238, 238, 238, 0.2)',
                      }}
                      pb={10}
                    >
                      <Text color="#565656" fw={600} size={14}>
                        Follow us
                      </Text>
                    </Box>
                    <Box mt={20}>
                      <Flex w={'100%'} wrap={'wrap'}>
                        <ActionIcon mr={15}>
                          <IconBrandFacebook onClick={() => { window.open(link_facebook, '_blank'); }} color="#565656" size={30} />
                        </ActionIcon>
                        <ActionIcon mr={15}>
                          <IconBrandInstagram onClick={() => { window.open(link_instagram, '_blank'); }} color="#565656" size={30} />
                        </ActionIcon>
                        <ActionIcon mr={15}>
                          <IconBrandTwitter onClick={() => { window.open(link_twitter, '_blank'); }} color="#565656" size={30} />
                        </ActionIcon>
                      </Flex>
                    </Box>
                  </Grid.Col>
                </Grid>
              </Box>
            </Container>
          </footer>
          <Box
            style={{
              background: theme.colors.brand[footer_bg_shade],
            }}
          >
            <Container size={'xl'} py={20}>
              <Text color="#565656" size={14}>
                © Quzone 2024. All rights reserved.
              </Text>
            </Container>
          </Box>
        </>
      )}
    </div>
  );
}

export default AppFooter;
