import { notification } from 'antd';

const successProperties = {
  background: '#f0fdf4',
  borderRadius: '8px',
};

const errorProperties = {
  background: '#FFEBEE',
  borderRadius: '8px',
};

const warningProperties = {
  background: '#fefce8',
  borderRadius: '8px',
};

export const createAlert = (type, message) => {
  if (type === 'error') {
    notification.error({
      message: 'Oops 😕',
      description: `${message}`,
      placement: 'top',
      style: errorProperties,
    });
  }
  if (type === 'warning') {
    notification.warning({
      message: 'Warning',
      description: `${message}`,
      placement: 'top',
      style: warningProperties,
    });
  }
  if (type === 'success') {
    notification.success({
      message: 'Success',
      description: `${message}`,
      placement: 'top',
      style: successProperties,
      btn: null,
    });
  }
};
