import { Box, Button, Flex, Grid, List, Modal, Text, TextInput } from '@mantine/core';
import { IconEdit, IconLocation, IconPlus, IconSearch } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { api, get_details_from_pincode } from '../services/master.service';
import { joiResolver, useForm } from '@mantine/form';
import Joi from 'joi';
import { showErrorToast, showSuccessToast } from '../utilities/Toast';
import { useMediaQuery } from '@mantine/hooks';
import { is_mobile_view } from '../config';

function AddAddressModal({ opened, onClose, isEditing, editData }) {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;

  useEffect(() => {
    api.get_cities().then((res) => {
      let data = res.data.data;
      let flat = data.flatMap((e) => e.city);
      setCities(flat);
    });
  }, []);

  useEffect(() => {
    if (isEditing) {
      console.log(editData);
      form.setValues({
        first_name: editData.first_name,
        address_line_1: editData.address_line_1,
        address_line_2: editData.address_line_2,
        phone_no: editData.phone_no,
        address_title: editData.address_title,
        pincode: editData.pincode,
        city: editData.city,
        state: editData.state,
        country: editData.country,
      });
    } else {
      form.reset();
    }
  }, [isEditing, editData]);

  const schema = Joi.object({
    address_title: Joi.string().required(2),
    first_name: Joi.string().required(2),
    phone_no: Joi.string().required(2),
    address_line_1: Joi.string().required(2),
    address_line_2: Joi.string().required(2),
    pincode: Joi.string().required(2).min(6),
    city: Joi.string().required(2),
    state: Joi.string().required(2),
    country: Joi.string().required(2),
  });

  const form = useForm({
    initialValues: {
      address_title: '',
      first_name: '',
      phone_no: '',
      address_line_1: '',
      address_line_2: '',
      pincode: '',
      city: '',
      state: '',
      country: '',
    },
    validate: joiResolver(schema),
  });

  const handlePinCodeChange = async (pincode) => {
    setIsLoading(true);
    await get_details_from_pincode.get_address_details(pincode).then((res) => {
      setIsLoading(false);
      console.log(res.data);
      if (res.data[0].Status !== 'Success') {
        showErrorToast({
          title: 'Error',
          message: 'Pincode Not Found',
        });
      } else {
        form.setValues({
          city: res.data[0].PostOffice[0].District,
          state: res.data[0].PostOffice[0].Circle,
          country: res.data[0].PostOffice[0].Country,
        });
        // console.log('id :', form.getFieldValue('id'));
      }
    });
  };

  const addAddress = async (values) => {
    if (isEditing) {
      setIsLoading(true);
      await api.edit_address({ ...values, id: editData.id }).then((res) => {
        setIsLoading(false);
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: 'Successfully edited address.',
          });
          onClose();
        } else {
          showErrorToast({
            title: 'Error',
            message: 'Error editing address.',
          });
        }
      });
    } else {
      setIsLoading(true);
      await api.add_address(values).then((res) => {
        setIsLoading(false);
        console.log(res.data);
        if (res.data.success) {
          showSuccessToast({
            title: 'Success',
            message: 'Successfully added address.',
          });
          onClose();
        } else {
          showErrorToast({
            title: 'Error',
            message: 'Error adding address.',
          });
        }
      });
    }
  };

  return (
    <div>
      <Modal
        opened={opened}
        onClose={onClose}
        styles={{ header: { borderBottom: '1px solid #eee' }, body: { padding: 0 } }}
        title={
          <Box px={10}>
            {isEditing ? (
              <Flex align={'center'}>
                <IconEdit size={16} />
                <Text ml={14} size={'16px'}>
                  Edit Address
                </Text>
              </Flex>
            ) : (
              <Flex align={'center'}>
                <IconPlus size={16} />
                <Text ml={14} size={'16px'}>
                  Add New Address
                </Text>
              </Flex>
            )}
          </Box>
        }
        size={'760px'}
        centered
      >
        <Box p={20}>
          <form
            onSubmit={form.onSubmit((values) => {
              addAddress(values);
            })}
          >
            <Grid gutter={'lg'}>
              <Grid.Col span={12}>
                <TextInput
                  placeholder="Enter Home / Office"
                  label="Address Title"
                  {...form.getInputProps('address_title')}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  placeholder="Enter First Name"
                  label="First Name"
                  {...form.getInputProps('first_name')}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  placeholder="Enter Phone Number"
                  label=" Phone Number"
                  {...form.getInputProps('phone_no')}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  placeholder="Enter Street Name"
                  label="Street Name"
                  {...form.getInputProps('address_line_1')}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 6}>
                <TextInput
                  placeholder="Enter Door No. / Building Name / Apartment Name"
                  label="Landmark"
                  {...form.getInputProps('address_line_2')}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 3}>
                <TextInput
                  disabled={isLoading}
                  placeholder="Enter Pincode"
                  label=" Pincode"
                  {...form.getInputProps('pincode')}
                  onChange={(e) => {
                    form.setFieldValue('pincode', e.target.value);
                    e.target.value.length > 5 && handlePinCodeChange(e.target.value);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 3}>
                <TextInput
                  // placeholder="Enter Address Line 2"
                  label="City"
                  {...form.getInputProps('city')}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 3}>
                <TextInput
                  // placeholder="Enter Address Line 2"
                  label="State"
                  {...form.getInputProps('state')}
                  disabled
                />
              </Grid.Col>
              <Grid.Col span={isMobileScreen ? 12 : 3}>
                <TextInput
                  // placeholder="Enter Address Line 2"
                  label=" Country"
                  {...form.getInputProps('country')}
                  disabled
                />
              </Grid.Col>
            </Grid>
            <Button mb={10} w={'100%'} mt={24} type="submit" disabled={isLoading}>
              {isEditing ? 'Edit Address' : 'Add New Address'}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  );
}

export default AddAddressModal;
