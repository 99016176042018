import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Container,
  Flex,
  Grid,
  Paper,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { api } from '../services/master.service';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { image_url, is_mobile_view } from '../config';

function CategoriesPage() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();

  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    api.get_categories().then((res) => {
      console.log(res.data.data);
      setCategories(res.data.data);
    });
  }, []);

  return (
    <div>
      <Container
        size={'xl'}
        fluid={isMobileScreen}
        px={isMobileScreen && 0}
        style={{ overflow: 'hidden', marginTop: isMobileScreen ? '150px' : '170px' }}
      >
        <Box mb={isMobileScreen ? 14 : 24} px={10}>
          <Breadcrumbs separator="/">
            <Link
              size={14}
              to={'/'}
              style={{
                textDecoration: 'none',
                color: 'inherit',
                fontSize: 14,
              }}
            >
              Home
            </Link>
            <Text size={14} color="dimmed">
              Categories
            </Text>
          </Breadcrumbs>
        </Box>

        {/* Categories */}
        <Box p={10}>
          {isMobileScreen ? (
            <></>
          ) : (
            <Text size={'xl'} mb={20} fw={600}>
              All Categories
            </Text>
          )}
          <Flex justify={'stretch'} wrap={'wrap'} gap={'lg'}>
            {categories.map((e) => (
              <Box
                sx={{
                  borderRadius: '6px',
                  cursor: 'pointer',
                  '&:hover': {
                    border: `2px solid ${theme.colors.brand[5]}`,
                  },
                }}
                key={e.id}
                p={14}
                onClick={() => {
                  navigate(`/category?id=${e.id}`);
                }}
                w={160}
                h={160}
                style={{
                  borderRadius: '6px',
                  backgroundImage: `url(${
                    e.image !== null
                      ? image_url + e.image
                      : 'https://i.imgur.com/EJLFNOw.png'
                  })`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              >
                <Flex h={'100%'} direction={'column'} align={'center'} justify={'center'}>
                  <Text
                    ta={'center'}
                    fz={14}
                    fw={900}
                    color={'black'}
                    p={6}
                    style={{ borderRadius: '30px' }}
                    bg={'rgba(255, 255, 255,0.6)'}
                  >
                    {e.title}{' '}
                    <img
                      src="https://img.icons8.com/pastel-glyph/64/0c4a6e/move-right.png"
                      width={isMobileScreen ? 24 : 30}
                    />
                  </Text>
                </Flex>
                {/* <img
                      alt=""
                      width={60}
                      src={
                        e.image_url
                          ? e.image_url + e.image
                          : 'https://i.imgur.com/EJLFNOw.png'
                      }
                      style={{
                        display: 'block',
                        margin: '0 auto',
                      }}
                    ></img>
                    <Text size={'14px'} align="center" mt={10} tt={'capitalize'}>
                      {e.title}
                    </Text>
                    <Text size={'12px'} color="dimmed" align="center" mb={0}>
                      120 Products
                    </Text> */}
              </Box>
            ))}
          </Flex>
        </Box>
      </Container>
    </div>
  );
}

export default CategoriesPage;
