import { Box, Button, Flex, Grid, Text, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/master.service';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import ViewOrderModal from '../../components/ViewOrderModal';
import moment from 'moment';
import { Badge, Col, Input, Modal, Rate, Row, Spin } from 'antd';
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  EyeFilled,
  LoadingOutlined,
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import confirm from 'antd/lib/modal/confirm';
import { order, review } from '../../services/website.service';
import { createAlert } from '../../components/Notification';
import { openConfirmModal } from '@mantine/modals';
import { is_mobile_view } from '../../config';
import { useMediaQuery } from '@mantine/hooks';

function Orders() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [viewModalOpened, setViewModalOpened] = useState(false);
  const [isQRModalVisible, setIsQRModalVisible] = useState(false);

  useEffect(() => {
    api.get_orders().then((res) => {
      console.log(res.data.data);
      setOrders(res.data.data);
    });
  }, []);

  const showPromiseConfirm = (id) => {
    openConfirmModal({
      title: `Are you sure you want to cancel the order ?`,
      children: (
        <Flex gap={'sm'}>
          <Text size="sm">You will not be able to revert the action.</Text>
        </Flex>
      ),
      labels: { confirm: 'Cancel the order', cancel: 'Close' },
      onCancel: () => console.log('Close'),
      onConfirm: () => {
        return new Promise(async (resolve, reject) => {
          await order.cancel(id).then((res) => {
            console.log(res);
            if (res.success) {
              resolve();
              api.get_orders().then((res) => {
                console.log(res.data.data);
                setOrders(res.data.data);
              });
              //setIsModalVisible(false);
            } else {
              reject();
            }
          });
        }).catch(() => console.log('Oops errors!'));
      },
      confirmProps: { color: 'red' },
    });
  };

  // const showPromiseConfirms = (id) => {
  //   confirm({
  //     title: `Are you sure you want to cancle the order ?`,
  //     centered: true,
  //     type: 'error',
  //     width: 450,
  //     icon: <ExclamationCircleOutlined />,
  //     content: `You will not be able to revert the action.`,
  //     okText: 'Cancel the order',
  //     okType: 'danger',
  //     onOk() {
  //       return new Promise(async (resolve, reject) => {
  //         await order.cancle(id).then((res) => {
  //           console.log(res);
  //           if (res.success) {
  //             resolve();
  //             api.get_orders().then((res) => {
  //               console.log(res.data.data);
  //               setOrders(res.data.data);
  //             });
  //             //setIsModalVisible(false);
  //           } else {
  //             reject();
  //           }
  //         });
  //       }).catch(() => console.log('Oops errors!'));
  //     },
  //     onCancel() {},
  //   });
  // };

  return (
    <Box mt={20}>
      <Flex direction={'row'} wrap={'wrap'} gap={'sm'}>
        {orders.map((e) => (
          <Box
            key={e.id}
            w={isMobileScreen ? '100%' : '49%'}
            p={20}
            mb={15}
            bg={'white'}
            sx={{
              border: '1px solid #eee',
              borderRadius: 6,
            }}
          >
            <Grid columns={12}>
              <Grid.Col span={6} md={6} lg={6}>
                <Text color="dimmed" size={'xs'}>
                  Order ID
                </Text>
                <Text size={14}> {e.order_id} </Text>
              </Grid.Col>
              <Grid.Col span={6} md={6} lg={6}>
                <Text color="dimmed" size={'xs'}>
                  Date
                </Text>
                <Text size={14}> {moment(e.timestamp).format('DD/MM/YYYY')}</Text>
              </Grid.Col>
              <Grid.Col span={6} md={6} lg={6}>
                <Text color="dimmed" size={'xs'}>
                  Status
                </Text>
                <Text size={14} tt={'capitalize'}>
                  {' '}
                  {e.status.replace(/_/gi, ' ')}
                </Text>
              </Grid.Col>
              <Grid.Col span={6} md={6} lg={6}>
                <Text color="dimmed" size={'xs'}>
                  Total
                </Text>
                <Text size={14}> ₹ {e.total} </Text>
              </Grid.Col>
              <Grid.Col span={12} md={12} lg={12}>
                <Flex gap={10} justify={'right'}>
                  {e.status !== 'accepted' ? (
                    <></>
                  ) : (
                    <Button
                      mt={5}
                      variant="outline"
                      size="xs"
                      display={'block'}
                      onClick={() => {
                        showPromiseConfirm(e.id);
                      }}
                    >
                      <CloseOutlined className="mr-2" style={{ marginRight: '5px' }} />{' '}
                      Cancel Order
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setSelectedOrder(e.id);
                      setViewModalOpened(true);
                    }}
                    mt={5}
                    variant="filled"
                    size="xs"
                    display={'block'}
                  >
                    View Order
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          </Box>
        ))}
      </Flex>

      <ViewOrderModal
        orderId={selectedOrder}
        opened={viewModalOpened}
        onClose={() => {
          setViewModalOpened(false);
        }}
      />
    </Box>
  );
}

export default Orders;
