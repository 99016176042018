import {
  ActionIcon,
  Box,
  Burger,
  Button,
  Collapse,
  Divider,
  Drawer,
  Flex,
  Group,
  Header,
  HoverCard,
  Image,
  Indicator,
  List,
  Menu,
  Modal,
  Overlay,
  ScrollArea,
  Text,
  TextInput,
  ThemeIcon,
  UnstyledButton,
  createStyles,
} from '@mantine/core';

import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconArrowsLeftRight,
  IconBook,
  IconChartPie3,
  IconChevronRight,
  IconCode,
  IconCoin,
  IconFingerprint,
  IconHeart,
  IconLogout,
  IconMapPin,
  IconNotification,
  IconSearch,
  IconSettings,
  IconShoppingCart,
  IconUser,
  IconUserCircle,
} from '@tabler/icons-react';
import { useContext, useEffect, useState } from 'react';
import CitySelectionModal from './CitySelectionModal';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { api } from '../services/master.service';
import { IconArrowNarrowLeft, IconX } from '@tabler/icons';
import { CartContext } from '../App';
import { header_bg_shade, header_text_shade, is_mobile_view, logo_name } from '../config';

const useStyles = createStyles((theme) => ({
  link: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    height: '100%',
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: 'none',
    color: theme.colors.brand[header_text_shade],
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan('sm')]: {
      height: 42,
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    }),

    '&:active': theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${1} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: 'Open source',
    description: 'This Pokémon’s cry is very loud and distracting',
  },
  {
    icon: IconCoin,
    title: 'Free for everyone',
    description: 'The fluid of Smeargle’s tail secretions changes',
  },
  {
    icon: IconBook,
    title: 'Documentation',
    description: 'Yanma is capable of seeing 360 degrees without',
  },
  {
    icon: IconFingerprint,
    title: 'Security',
    description: 'The shell’s rounded shape and the grooves on its.',
  },
  {
    icon: IconChartPie3,
    title: 'Analytics',
    description: 'This Pokémon uses its flying ability to quickly chase',
  },
  {
    icon: IconNotification,
    title: 'Notifications',
    description: 'Combusken battles with the intensely hot flames it spews',
  },
];

export default function AppHeader() {
  const [logo, setLogo] = useState('');
  useEffect(() => {
    import(`../static/images/${logo_name}`).then((module) => {
      setLogo(module.default);
    });
  }, [])
  const navigate = useNavigate();
  const location = useLocation();
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const { cartItemCount, setCartItemCount, search, setSearch } = useContext(CartContext);

  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();

  const [citySelectionOpened, setCitySelectionOpened] = useState(false);
  const [categories, setCategories] = useState([]);
  //const [cartCount, setCartCount] = useState(null);

  useEffect(() => {
    if (!localStorage.getItem('city_pincode')) {
      setCitySelectionOpened(true);
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate(`/products?search=${event.target.value}`);
      //setSearchOpened(false);
    }
  };

  useEffect(() => {
    api.get_categories().then((res) => {
      console.log(res.data.data);
      setCategories(res.data.data);
    });
  }, []);

  useEffect(() => {
    api.get_cart().then((res) => {
      console.log(res.data.data);
      //setCartCount(res.data.data?.cartitem.length);
      setCartItemCount(res.data.data?.cartitem.length);
    });
  }, []);

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={22} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  if (
    (!localStorage.getItem('city_name') ||
      !localStorage.getItem('city_id') ||
      !localStorage.getItem('city_pincode')) &&
    !citySelectionOpened
  ) {
    setCitySelectionOpened(true);
  }

  return (
    <>
      <Box
        pb={20}
        style={{
          position: 'fixed',
          top: '0',
          width: '100%',
          zIndex: 10,
        }}
      >
        <Header>
          <Group
            justify="space-between"
            pt={'lg'}
            px="md"
            position="apart"
            sx={{ height: '100%' }}
          >
            {/* <MantineLogo size={30} /> */}
            <Flex align={'center'} justify={'space-between'} mr={'3%'}>
              {location.pathname !== '/' &&
                location.pathname !== '/login' &&
                isMobileScreen ? (
                <IconArrowNarrowLeft
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              ) : (
                <></>
              )}
              <Box h={'100%'}>
                <Text
                  fw={800}
                  size={'lg'}
                  style={{
                    letterSpacing: '-1px',
                  }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                    }}
                    to={'/'}
                  >
                    <img
                      src={logo}
                      width={150}
                      height={49}
                      style={{
                        objectFit: 'cover',
                      }}
                      alt="logo"
                    />
                  </Link>
                </Text>
              </Box>
            </Flex>
            <Group
              justify={'center'}
              w={'30%'}
              h={'100%'}
              sx={{ height: '100%', display: isMobileScreen ? 'none' : 'block' }}
              spacing={0}
            >
              <Box w={'100%'}>
                <TextInput
                  bg={'white'}
                  variant="unstyled"
                  placeholder="Search Among 1000 of products..."
                  radius={'500px'}
                  icon={<IconSearch style={{ marginLeft: 10 }} width={18} />}
                  rightSection={
                    search !== '' ? (
                      <IconX
                        onClick={() => {
                          if (location.pathname === '/products') {
                            navigate(`/products?search=`);
                          }
                          setSearch('');
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  size="sm"
                  style={{
                    border: '2px solid ' + theme.colors.brand[8],
                    borderRadius: '500px',
                  }}
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  onKeyDown={(val) => {
                    handleKeyPress(val);
                  }}
                />
              </Box>
            </Group>
            <Group
              h={'100%'}
              position="right"
              sx={isMobileScreen ? { display: 'none' } : {}}
            >
              {localStorage.getItem('user_token') ? (
                <>
                  <Menu shadow="md">
                    <Menu.Target>
                      <ActionIcon>
                        <IconUserCircle />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        onClick={() => {
                          navigate('/account');
                        }}
                        icon={<IconUser size={14} />}
                      >
                        My Account
                      </Menu.Item>
                      <Menu.Item
                        onClick={() => {
                          let city_name = localStorage.getItem('city_name');
                          let city_pincode = localStorage.getItem('city_pincode');
                          let city_id = localStorage.getItem('city_id');
                          localStorage.clear();
                          localStorage.setItem('city_name', city_name);
                          localStorage.setItem('city_pincode', city_pincode);
                          localStorage.setItem('city_id', city_id);
                          navigate('/');
                        }}
                        icon={<IconLogout size={14} />}
                        color="red"
                      >
                        Logout
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>

                  <ActionIcon
                    onClick={() => {
                      navigate('/cart');
                    }}
                  >
                    <Indicator
                      inline
                      label={cartItemCount?.toString()}
                      size={16}
                      disabled={cartItemCount === 0}
                    >
                      <IconShoppingCart />
                    </Indicator>
                  </ActionIcon>
                </>
              ) : (
                <ActionIcon
                  onClick={() => {
                    navigate('/login');
                  }}
                >
                  <IconUserCircle />
                </ActionIcon>
              )}
              <Button
                variant="subtle"
                leftIcon={<IconMapPin />}
                size="xs"
                onClick={() => {
                  setCitySelectionOpened(true);
                }}
                maw={'200px'}
              >
                <Text truncate="end">
                  {localStorage.getItem('city_name')
                    ? localStorage.getItem('city_name')
                    : 'Select City'}
                </Text>
              </Button>

              {/* <Button variant="default">Log in</Button>
            <Button>Sign up</Button> */}
            </Group>

            {/* <Burger
              h={'100%'}
              opened={drawerOpened}
              onClick={toggleDrawer}
              className={classes.hiddenDesktop}
            /> */}
            {isMobileScreen ? (
              <Group>
                {localStorage.getItem('user_token') ? (
                  <>
                    <ActionIcon
                      onClick={() => {
                        navigate('/cart');
                      }}
                    >
                      <Indicator
                        inline
                        label={cartItemCount?.toString()}
                        size={16}
                        disabled={cartItemCount === 0}
                      >
                        <IconShoppingCart />
                      </Indicator>
                    </ActionIcon>
                    <ActionIcon
                      onClick={() => {
                        setCitySelectionOpened(true);
                      }}
                    >
                      <IconMapPin
                        color={localStorage.getItem('city_name') ? 'green' : '#adb5bd'}
                      />
                    </ActionIcon>
                  </>
                ) : (
                  <></>
                )}
                {/* <ActionIcon
                  onClick={() => {
                    setSearchOpened(true);
                  }}
                >
                  <IconSearch />
                </ActionIcon> */}
              </Group>
            ) : (
              <></>
            )}
          </Group>
          <Flex
            align={'center'}
            justify={'center'}
            mt={20}
            p={isMobileScreen ? 5 : 0}
            pt={isMobileScreen ? 5 : 20}
            style={{
              borderTop: '1px solid #eee',
              boxShadow: '0px 6px 10px ' + theme.colors.brand[0],
            }}
            bg={theme.colors.brand[header_bg_shade]}
          >
            {isMobileScreen ? (
              <Box w={'90%'}>
                <TextInput
                  bg={'white'}
                  variant="unstyled"
                  placeholder="Search Among 1000 of products..."
                  radius={'500px'}
                  icon={<IconSearch style={{ marginLeft: 10 }} width={18} />}
                  rightSection={
                    search !== '' ? (
                      <IconX
                        onClick={() => {
                          if (location.pathname === '/products') {
                            navigate(`/products?search=`);
                          }
                          setSearch('');
                        }}
                      />
                    ) : (
                      <></>
                    )
                  }
                  size="xs"
                  style={{
                    border: '2px solid ' + theme.colors.brand[8],
                    borderRadius: '500px',
                  }}
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  onKeyDown={(val) => {
                    handleKeyPress(val);
                  }}
                />
              </Box>
            ) : (
              <></>
            )}
            <Group
              sx={
                isMobileScreen ? { height: '100%', display: 'none' } : { height: '100%' }
              }
              justify="center"
              spacing={0}
              mb={'lg'}
            >
              <span
                onClick={() => {
                  navigate('/');
                }}
                className={classes.link}
              >
                Home
              </span>

              <span
                onClick={() => {
                  navigate('/products');
                }}
                className={classes.link}
              >
                Our Products
              </span>

              {location.pathname !== '/' ? (
                <>
                  <span
                    className={classes.link}
                    onClick={() => {
                      navigate('/categories');
                    }}
                  >
                    Categories
                  </span>
                </>
              ) : (
                <span
                  className={classes.link}
                >
                  <HoverCard shadow="md">
                    <HoverCard.Target>
                      <span
                        onClick={() => {
                          navigate('/categories');
                        }}>Explore Categories</span>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Box w={200}>
                        <ul
                          style={{
                            margin: 0,
                            padding: 0,
                            listStyleType: 'none',
                          }}
                        >
                          {categories.map((e) => (
                            <li
                              key={e.id}
                              style={{
                                textTransform: 'capitalize',
                                marginBottom: '5px',
                              }}
                            >
                              <Box
                                mb={5}
                                sx={{
                                  borderRadius: 5,
                                }}
                              >
                                <Menu
                                  trigger="hover"
                                  position="right-start"
                                  shadow="md"
                                  width={200}
                                >
                                  <Menu.Target>
                                    <Flex
                                      align={'center'}
                                      justify={'space-between'}
                                      py={5}
                                      pl={15}
                                      pr={5}
                                      onClick={() => {
                                        navigate(`/category/?id=${e.id}`);
                                      }}
                                    >
                                      <Text mb={0}>{e.title}</Text>
                                      <ActionIcon>
                                        <IconChevronRight />
                                      </ActionIcon>
                                    </Flex>
                                  </Menu.Target>
                                  <Menu.Dropdown>
                                    <Menu.Label> {e.title} </Menu.Label>
                                    <Menu.Divider />
                                    {e.sub_category?.map((x) => (
                                      <Menu.Item
                                        onClick={() => {
                                          navigate(
                                            `/category/?id=${e.id}&subcategory=${x.id}`,
                                          );
                                        }}
                                        sx={{
                                          fontSize: '13px',
                                        }}
                                        tt={'capitalize'}
                                      >
                                        {x.title}
                                      </Menu.Item>
                                    ))}
                                  </Menu.Dropdown>
                                </Menu>
                              </Box>
                            </li>
                          ))}
                        </ul>
                      </Box>
                    </HoverCard.Dropdown>
                  </HoverCard>
                </span>
              )}
              {localStorage.getItem('user_token') ? (
                <span
                  className={classes.link}
                  onClick={() => {
                    navigate('/account');
                  }}
                >
                  My Orders
                </span>
              ) : (
                <></>
              )}
            </Group>
          </Flex>
        </Header>

        <CitySelectionModal
          opened={citySelectionOpened}
          onClose={() => {
            //setCitySelectionOpened(false);
          }}
        />

        {/* <Modal
          opened={searchOpened}
          onClose={() => {
            setSearch('');
            setSearchOpened(false);
          }}
          title="Search Products"
        >
          <Box w={'100%'} mb={15} mt={10}>
            <TextInput
              variant="unstyled"
              placeholder="Search Among 1000 of products..."
              radius={'500px'}
              icon={<IconSearch style={{ marginLeft: 10 }} width={18} />}
              size="sm"
              style={{
                border: '2px solid ' + theme.colors.brand[8],
                borderRadius: '500px',
              }}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              onKeyDown={(val) => {
                handleKeyPress(val);
              }}
            />
          </Box>
        </Modal> */}

        {/* <Drawer
          opened={drawerOpened}
          onClose={closeDrawer}
          size="100%"
          padding="md"
          title="Navigation"
          className={classes.hiddenDesktop}
          zIndex={1000000}
        >
          <ScrollArea h={`calc(100vh - ${60})`} mx="-md">
            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

            <a href="/" className={classes.link}>
              Home
            </a>
            <Collapse in={linksOpened}>{links}</Collapse>
            <a href="/categories" className={classes.link}>
              Explore Categories
            </a>
            <a href="/products" className={classes.link}>
              Our Products
            </a>
            {localStorage.getItem('user_token') ? (
              <a href="/account" className={classes.link}>
                My Orders
              </a>
            ) : (
              <></>
            )}

            <Divider my="sm" color={theme.colorScheme === 'dark' ? 'dark.5' : 'gray.1'} />

            <Group position="center" grow pb="xl" px="md">
              <Button variant="default">Log in</Button>
              <Button>Sign up</Button>
            </Group>
          </ScrollArea>
        </Drawer> */}
      </Box>
    </>
  );
}
