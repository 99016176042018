import { httpClient } from '../lib/axios';
const cityId = localStorage.getItem('city_id');

export const order = {
  cancel: async (id) => {
    return httpClient
      .put(`/order/`, { status: 'cancelled', order_id: id })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const review = {
  // get: async () => await httpClient.get(`/product/offer/`),
  add: async (payload) => {
    return httpClient
      .post(`/review/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  get: async () => {
    return httpClient
      .get(`/review/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const carts = {
  add: async (payload) => {
    return httpClient
      .post(`/cart/?add_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  checkCoupon: async (payload) => {
    return httpClient
      .post(`/cart/?apply_coupon=true&city_id=${cityId}`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  // get: async () => {
  //   return httpClient
  //     .get(`/cart/`)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // },

  get: async () => {
    return new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve(
            httpClient
              .get(`/cart/`)
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err;
              }),
          ),
        300,
      ),
    );
  },
  editItem: async (payload) => {
    return httpClient
      .post(`/cart/?edit_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  removeItem: async (payload) => {
    return httpClient
      .post(`/cart/?remove_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};
