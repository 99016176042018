import {
  ActionIcon,
  Box,
  Button,
  Flex,
  Grid,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/master.service';
import { showErrorToast, showSuccessToast } from '../../utilities/Toast';
import ViewOrderModal from '../../components/ViewOrderModal';
import moment from 'moment';
import { IconEdit, IconMinus, IconPlus, IconTrash } from '@tabler/icons-react';
import AddAddressModal from '../../components/AddAddressModal';
import { is_mobile_view } from '../../config';

function Addresses() {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();

  const [viewModalOpened, setViewModalOpened] = useState(false);
  const [addressModalOpened, setAddressModalOpened] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState({});

  const [addresses, setAddresses] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    api.get_addresses().then((res) => {
      console.log(res.data.data);
      setAddresses(res.data.data);
    });
  }, []);

  const getData = () => {
    api.get_addresses().then((res) => {
      console.log(res.data.data);
      setAddresses(res.data.data);
    });
  };

  const deleteAddress = async (id) => {
    await api.delete_address(id).then((res) => {
      setIsLoading(false);
      console.log(res.data);
      if (res.data.success) {
        showSuccessToast({
          title: 'Success',
          message: 'Successfully deleted address.',
        });
        getData();
      } else {
        showErrorToast({
          title: 'Error',
          message: 'Error deleting address.',
        });
      }
    });
  };

  return (
    <Box mt={20}>
      <Grid>
        {addresses.map((e) => (
          <Grid.Col span={12} md={6} lg={4}>
            <Box
              bg={'white'}
              p={20}
              sx={{
                cursor: 'pointer',
                borderRadius: 3,
                border: '1px solid #eee',
              }}
            >
              <Flex justify={'space-between'} align={'center'}>
                <Text fw={600} size={16} mb={5}>
                  {e.address_title}
                </Text>
                <Flex>
                  <ActionIcon
                    onClick={() => {
                      setAddressModalOpened(true);
                      setIsEditing(true);
                      setEditData(e);
                    }}
                  >
                    <IconEdit size={15} />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => {
                      deleteAddress(e.id);
                    }}
                  >
                    <IconTrash size={15} />
                  </ActionIcon>
                </Flex>
              </Flex>
              <Text size={14}>{e.first_name}.</Text>
              <Text size={14}>{e.phone_no}</Text>
              <Text size={14}>{e.address_line_1}</Text>
              <Text size={14}>{e.address_line_2}</Text>
              <Text size={14}>
                {e.city}, {e.state}, {e.country}, {e.pincode}.
              </Text>
            </Box>
          </Grid.Col>
        ))}
      </Grid>
      <Button
        mt={20}
        variant="outline"
        leftIcon={<IconPlus size={14} />}
        onClick={() => {
          setAddressModalOpened(true);
          setIsEditing(false);
          setEditData({});
        }}
      >
        Add New Address
      </Button>

      {addressModalOpened && (
        <AddAddressModal
          opened={addressModalOpened}
          isEditing={isEditing}
          editData={editData}
          onClose={() => {
            setAddressModalOpened(false);
            getData();
          }}
        />
      )}
    </Box>
  );
}

export default Addresses;
