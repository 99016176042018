import { Carousel } from '@mantine/carousel';
import {
  ActionIcon,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  HoverCard,
  Image,
  Menu,
  Modal,
  Paper,
  Rating,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import {
  IconArrowsMaximize,
  IconHeart,
  IconMoneybag,
  IconPercentage,
  IconPlus,
  IconStar,
  IconStars,
  IconTrendingUp,
} from '@tabler/icons-react';
import { image_url, is_mobile_view } from '../config';
import { useNavigate } from 'react-router';
import { useMediaQuery } from '@mantine/hooks';
import { IconShoppingCart } from '@tabler/icons';
import { useRef } from 'react';

export default function ProductCard(props) {
  let isMobileScreen = useMediaQuery('(max-width: 768px)');
  isMobileScreen = is_mobile_view ? is_mobile_view : isMobileScreen;
  const theme = useMantineTheme();
  const navigate = useNavigate();

  const cartRef = useRef();

  return (
    <div>
      <Paper
        className="product-box"
        bg="white"
        p={isMobileScreen ? 10 : 24}
        shadow="sm"
        sx={{
          width: '100%',
          border: '2px solid transparent',
          cursor: 'pointer',
          transition: '.3s ease',
          borderRadius: 6,
          overflow: 'hidden',
          '&:hover': {
            border: `2px solid ${theme.colors.brand[5]}`,
            '& .product-actions': {
              visibility: 'visible',
              opacity: 1,
              right: 0,
              transform: 'none',
            },
          },
        }}
      >
        <Box
          className="product-box__top-area"
          pos={'relative'}
          onClick={() => {
            navigate('/product' + `/?id=${props.object.id}`);
          }}
        >
          <Image
            src={image_url + props.object.thumbnail}
            width={'100%'}
            mx={'auto'}
            mb={15}
            height={isMobileScreen ? 100 : 150}
            fit="contain"
            sx={{ borderRadius: 4 }}
          ></Image>
          {/* <Box pos={'absolute'} top={-10} right={-10}>
            <Flex
              justify={'center'}
              align={'center'}
              bg={theme.colors.brand[5]}
              w={35}
              h={35}
              sx={{
                borderRadius: 40,
              }}
            >
              <Text
                color="white"
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                }}
              >
                -15%
              </Text>
            </Flex>
          </Box> */}
        </Box>

        <Text size={'14px'} fw={'500'} mb={5} lineClamp={1}>
          {props.object.title}
        </Text>
        {isMobileScreen ? (
          <></>
        ) : (
          <>
            {props.object.stock_qty <= 0 ? (
              <Text size={'10px'} fw={'500'} mb={5} lineClamp={1} color="red">
                Out of Stock!
              </Text>
            ) : props.object.stock_qty > 3 ? (
              <></>
            ) : (
              <Text size={'10px'} fw={'500'} mb={5} lineClamp={1} color="green">
                only {props.object.stock_qty} left Hurry!
              </Text>
            )}
          </>
        )}
        {isMobileScreen ? (
          <></>
        ) : (
          <Flex align={'top'}>
            <Rating readOnly mb={15} defaultValue={props.object.review} value={props.object.review} size="xs" />
            <Text ml={5} size={'11px'}>
              ( {props.object.review} )
            </Text>
          </Flex>
        )}

        <Flex align={'center'} mb={15}>
          <Text strikethrough size={12} mr={10} color="gray" opacity={0.7}>
            ₹ {props.object.mrp}
          </Text>
          <Text fw={500} size={16} width={'100%'}>
            ₹ {props.object.price}
          </Text>
        </Flex>
        <Button
          variant={isMobileScreen ? 'light' : 'filled'}
          radius={50}
          ref={cartRef}
          size="xs"
          w={'100%'}
          //style={{ border: '1px solid green' }}
          onMouseLeave={() => {
            cartRef.current.style.border = 'none';
          }}
          onClick={() => {
            props.addToCart(props.object.id);
            if (isMobileScreen) {
              cartRef.current.style.border = '1px solid lightgreen';
            }
          }}
        >
          <IconPlus style={{ width: 14, marginRight: 8 }} /> Add To Cart
        </Button>
      </Paper>
    </div>
  );
}
